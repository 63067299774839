import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import * as AccountNotificationState from '../../store/AccountNotifications';
import { fetchApi } from '../../services/api';
import { LoadOneReconnectPolicy } from '../../services/signalr/retryPolicy';
import { ApplicationState } from '../../store';
import { openWindow } from '../../services/openWindow';
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import * as Sentry from '@sentry/browser';
import { version } from '../../version';
import { ILink } from '../../types/link';
import { paths } from '../../routes';
import GlobalSearch from '../GlobalSearch';
import ProblemDialog from './ProblemDialog';
import { SvgIcon } from "@progress/kendo-react-common";
import { bellIcon } from '@progress/kendo-svg-icons';
const Favico = require('favico.js');

interface WebNotification {
  Id: string;
  CreatedDateTime: string;
  Source: number;
  SourceEntity: number;
  ForUser: string;
  Text: string;
  Color: string;
  Sound: string;
  Link: string;
  PopupLink: string;
  Dismissed: boolean;
}

const connector = connect(
  (state: ApplicationState) => state.accountNotifications,
  AccountNotificationState.actionCreators
)

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface State {
  id: string;
  username: string;
  loading: boolean;
  sound: string;
  links: ILink[];
}

class NavBar extends React.Component<Props, State> {

  private connection: HubConnection | null;
  private favico: any;

  constructor(props: Props) {
    super(props);

    this.connection = null;

    this.state = {
      id: '',
      username: '',
      loading: true,
      sound: '',
      links: [],
    };

    this.dismissAllNotifications = this.dismissAllNotifications.bind(this);
    this.createSignalRConnection = this.createSignalRConnection.bind(this);
    this.emailSupport = this.emailSupport.bind(this);
  }

  public componentDidMount() {
    this.favico = new Favico({ animation: 'pop' });
    fetchApi('/api/Account/Info')
      .then((response: { Id: string, Username: string, Links: ILink[] }) => {
        this.setState({
          loading: false,
          id: response.Id,
          username: response.Username,
          links: response.Links,
        });

        Sentry.setUser({ id: response.Id, email: response.Username });
      });

    // Initial Fetch
    this.props.requestAccountNotifications();

    this.createSignalRConnection();
  }

  public componentWillUnmount() {
    if (this.connection) {
      this.connection.stop();
    }
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.accountNotifications && !this.props.accountNotifications) {
      this.favico.badge(nextProps.accountNotifications.length);
    } else if (nextProps.accountNotifications && nextProps.accountNotifications.length !== this.props.accountNotifications.length) {
      this.favico.badge(nextProps.accountNotifications.length);
    }
  }

  public render() {
    return <React.Fragment>
      {this.props.newVersion && <div className="p-3 bg-warning">
        <a href="#" onClick={(e) => { e.preventDefault(); window.location.reload(); }}>
          <div className="container text-dark font-weight-bold">New Version Available. Click Here.</div>
        </a>
      </div>}
      {this.props.problem && <ProblemDialog problem={this.props.problem} onClose={this.props.dismissProblem} />}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <Link to='/' className="navbar-brand">Load One LLC</Link>
        {this.state.sound && <audio src={this.state.sound} onEnded={() => this.setState({ sound: '' })} autoPlay />}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {this.connection !== null && <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle notification-dropdown-toggle"
                id="navbarDropdownNotifiations"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <SvgIcon icon={bellIcon} color={this.props.accountNotifications.length > 0 ? 'red' : 'rgba(255,255,255,.5)'} />
              </a>
              <div
                className="dropdown-menu notification-dropdown-menu"
                aria-labelledby="navbarDropdownNotifiations"
              >
                {this.props.accountNotifications.map((notification, index) => {
                  return (notification.PopupLink ?
                    <Link
                      key={index.toString()}
                      className="dropdown-item"
                      to={notification.Link}
                      onClick={(e) => openWindow(notification.PopupLink)}
                    >
                      {notification.Text}
                    </Link>
                    :
                    <Link
                      key={index.toString()}
                      className="dropdown-item"
                      to={notification.Link}
                    >
                      {notification.Text}
                    </Link>);
                })}
                {this.props.accountNotifications.length ?
                  [
                    <div key="div2" className="dropdown-divider" />,
                    <Link key="all" className="dropdown-item" to="/Account/Notifications">
                      View All Notifications
                    </Link>,
                    <div key="div3" className="dropdown-divider" />,
                    <a
                      key="clear"
                      href=""
                      className="dropdown-item"
                      onClick={this.dismissAllNotifications}>
                      Clear All Notifications
                    </a>
                  ] : <a key="none" className="dropdown-item disabled" href="">No Notifications</a>}
              </div>
            </li>}

            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdownLoadBoard"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onDoubleClick={() => window.location.href = "/LoadBoard/Index"}
              >
                Load Board
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownLoadBoard">
                <Link className="dropdown-item" to="/LoadBoard/Index">Open Shipments</Link>
                <Link className="dropdown-item" to="/LoadBoard/BidHistory">Bid History</Link>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdownTrack"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onDoubleClick={() => window.location.href = "/Track/Index"}
              >
                Track
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownTrack">
                <Link className="dropdown-item" to="/Track/Index">Track</Link>
                <Link className="dropdown-item" to="/Track/Positions">Positions</Link>
                <Link className="dropdown-item" to="/ScheduledOrders">Scheduled</Link>
                <Link className="dropdown-item" to="/ShiftTurnover">Shift Turnover</Link>
                <Link className="dropdown-item" to="/DeadHead/Create">DeadHead Trip</Link>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdownQuote"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onDoubleClick={() => window.location.href = "/Quote/Index"}
              >
                Quote
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownQuote">
                <Link className="dropdown-item" to="/Quote/Index">New Quote</Link>
                <Link className="dropdown-item" to="/Quote/History">History</Link>
                <Link className="dropdown-item" to="/Quote/BlindBidQuote">Blind Bid Quote</Link>
                <Link className="dropdown-item" to="/Quote/LoadBoard">Carrier Load Board</Link>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdownAssets"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Assets
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownAssets">
                <Link className="dropdown-item" to="/Assets/Drivers">Drivers</Link>
                <Link className="dropdown-item" to="/Assets/Vehicles">Vehicles</Link>
                <Link className="dropdown-item" to="/Assets/Trailers">Trailers</Link>
                <Link className="dropdown-item" to="/Assets/Owners">Owners</Link>
                <Link className="dropdown-item" to="/Assets/Carriers">Carriers</Link>
                <Link className="dropdown-item" to="/Assets/Carriers/Contacts">Carrier Contacts</Link>
                <Link className="dropdown-item" to="/CarrierRankings">Carrier Rankings</Link>
                <Link className="dropdown-item" to="/Carrier/Groups">Carrier Groups</Link>
                <Link className="dropdown-item" to="/FleetMessages">Fleet Messages</Link>
                <Link className="dropdown-item" to="/Assets/WorkloadHistory">Workload History</Link>
                <Link className="dropdown-item" to="/Assets/Inspections">Inspections</Link>
                <Link className="dropdown-item" to="/Driver/Chat">Driver Chat</Link>
                <Link className="dropdown-item" to="/Assets/DriverAccidents">Accidents / Incidents</Link>
                <Link className="dropdown-item" to="/LoadMap/Index">Load Map</Link>
                <Link className="dropdown-item" to="/Assets/ComplianceDates">Compliance Dates</Link>
                <Link className="dropdown-item" to="/DriverCardLogs/Index">Card Logs</Link>
                <Link className="dropdown-item" to="/Assets/UploadBestpassTollReport">Upload Bestpass Toll Report</Link>
                <Link className="dropdown-item" to="/Assets/DriverMetrics">Driver Metrics</Link>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdownCustomers"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onDoubleClick={() => window.location.href = "/Customers"}
              >
                Customers
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownCustomers">
                <Link className="dropdown-item" to="/Customers">Customers</Link>
                <Link className="dropdown-item" to="/Customers/Contacts">Customer Contacts</Link>
                <Link className="dropdown-item" to="/Salespeople">Salespeople</Link>
                <Link className="dropdown-item" to="/CustomsBrokers">Customs Brokers</Link>
                <Link className="dropdown-item" to="/Customers/EmailAliases">Email Aliases</Link>
                <Link className="dropdown-item" to="/Rating/Index">Tariff Rate Structures</Link>
                <Link className="dropdown-item" to="/Customers/Merge">Merge Customers</Link>
                <Link className="dropdown-item" to="/Customers/Backhaul">Backhaul Assistant</Link>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdownBilling"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Billing
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownBilling">
                <Link className="dropdown-item" to="/Billing/Index/0">Invoice Listing</Link>                
                <Link className="dropdown-item" to="/Billing/Index/1">Post Invoice Batch</Link>
                <Link className="dropdown-item" to="/Billing/Index/2">Download Invoice Batch</Link>
                <Link className="dropdown-item" to="/Billing/Index/3">Invoice Gateway</Link>                
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdownSettlements"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Settlements
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownSettlements">
                <Link className="dropdown-item" to="/Settlements/PayListing">Pay Listing</Link>
                <Link className="dropdown-item" to="/Settlements/DeductionCOAs">Deduction Types & COA Mapping</Link>
                <Link className="dropdown-item" to="/Settlements/ScheduledDeductions">Deduction Scheduler</Link>
                <Link className="dropdown-item" to="/Settlements/GenerateDeductions">Pay Periods, Sheets & Deduction Tracker</Link>
                <Link className="dropdown-item" to="/Settlements/PostPayBatch">Post Pay Batch</Link>
                <Link className="dropdown-item" to="/PayTrip/Create">Additional Pay Trip</Link>
                <Link className="dropdown-item" to="/Settlements/UploadVehicleFuelDeductions">Upload Vehicle Fuel Deductions</Link>
                <Link className="dropdown-item" to="/Settlements/UploadDriverChargebacks">Upload Driver/Vehicle Chargebacks</Link>
                <Link className="dropdown-item" to="/Settlements/UploadEmailOwnerFuelReport">Upload/Email Owner Fuel Report</Link>
                <Link className="dropdown-item" to="/Settlements/UploadAmortizedVehicleChargebacks">Upload Amortized Vehicle Chargebacks</Link>
                <Link className="dropdown-item" to={paths.PayRateLinehaul}>Pay Rate Linehaul</Link>
                <Link className="dropdown-item" to={paths.PayRateAccessorial}>Pay Rate Accessorial</Link>
              </div>
            </li>

            {this.state.links.find(x => x.Name == "Zoom") && <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdownZoom"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Zoom
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownZoom">
                <Link className="dropdown-item" to={paths.ZoomCallLogs}>Call Logs</Link>
                <Link className="dropdown-item" to={paths.ZoomOperationalLogs}>Operational Logs</Link>
              </div>
            </li>}

            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdownPurchaseOrder"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onDoubleClick={() => window.location.href = "/PurchaseOrder/Index"}
              >
                Purchase Orders
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownPurchaseOrder">
                <Link className="dropdown-item" to="/PurchaseOrder/Index">Purchase Orders</Link>
                <Link className="dropdown-item" to="/PurchaseOrder/Vendors">Vendors</Link>
                <Link className="dropdown-item" to="/PurchaseOrder/GeneralLedgerAccounts">GL Accounts</Link>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">3rd Party Sites<span className="caret"></span></a>
              <ul className="dropdown-menu">
                <li><a href="http://apt4.activeaero.com/Login/LoginForm.cfm" target="_blank" className="dropdown-item">Active Aero</a></li>
                <li><a href="https://acument.mercurygate.net/MercuryGate/login/mgLogin.jsp?inline=true&kick=1515689653255" target="_blank" className="dropdown-item">Acument</a></li>
                <li><a href="http://www.ardmorelogistics.com/inter/index.php/home" target="_blank" className="dropdown-item">Ardmore Power Logistics</a></li>
                <li><a href="https://web.cargobase.com/login/" target="_blank" className="dropdown-item">Cargobase (Continental Automotive)</a></li>
                <li><a href="http://truckers.exworks.com/" target="_blank" className="dropdown-item">Ex Works</a></li>
                <li><a href="https://ecco.expeditors.com/exp/zseries/appletStartupExternal.html" target="_blank" className="dropdown-item">Expeditors</a></li>
                <li><a href="https://gtms.glovisusa.com" target="_blank" className="dropdown-item">Glovis (IE Only)</a></li>
                <li><a href="http://apps.leanlogistics.com" target="_blank" className="dropdown-item">Leading Edge/Lean Logistics</a></li>
                <li><a href="https://web.menlolog.com/lmsweb/" target="_blank" className="dropdown-item">Menlo</a></li>
                <li><a href="https://otm.xpo.com/GC3/glog.webserver.servlet.umt.Login" target="_blank" className="dropdown-item">Menlo Worldwide Logistics</a></li>
                <li><a href="https://logon.onenetwork.com:443/sso/logon.sso" target="_blank" className="dropdown-item">One Networks - Piston/Dana</a></li>
                <li><a href="https://www.penskelogistics.net/csr/" target="_blank" className="dropdown-item">Penske - Whirlpool/BRP/MidwayZF/Toyota/Transfreight</a></li>
                <li><a href="https://pensketm.mercurygate.net/MercuryGate/login/mgLogin.jsp?inline=true" target="_blank" className="dropdown-item">Penske Logistics</a></li>
                <li><a href="https://rushscm.mercurygate.net/MercuryGate/login/mgLogin.jsp?inline=true" target="_blank" className="dropdown-item">Rush</a></li>
                <li><a href="http://sonoco10.shipcomm.com/Web/" target="_blank" className="dropdown-item">Sunoco</a></li>
                <li><a href="https://www.capacitygateway.com" target="_blank" className="dropdown-item">XPO - Capacity</a></li>
                <li><a href="https://www.expedite.xpo.com" target="_blank" className="dropdown-item">XPO - Expedite</a></li>
                <li><a href="https://specialized.xpo.com/carrier/Login.asp?login=1" target="_blank" className="dropdown-item">XPO - Specialized</a></li>
                <li><a href="https://yrc.freightcontroltower.com" target="_blank" className="dropdown-item">YRC</a></li>
              </ul>
            </li>

            {this.state.links.find(x => x.Name == "Hangfire") && <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdownHangfire"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onDoubleClick={() => window.location.href = "/hangfire"}
              >
                Hangfire
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownHangfire">
                <a className="dropdown-item" href="/hangfire">Dashboard</a>
                <a className="dropdown-item" href="/hangfire/jobs/enqueued">Jobs</a>
                <a className="dropdown-item" href="/hangfire/retries">Retries</a>
                <a className="dropdown-item" href="/hangfire/recurring?from=0&amp;count=1000">Recurring</a>
              </div>
            </li>}
          </ul>

          {!this.state.loading && <ul className="nav navbar-nav navbar-right">
            <GlobalSearch />
            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                id="navbarDropdownAccount"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {this.state.id && <img className="rounded-circle" title={this.state.username} height="34" src={`/api/User/Photo/${this.state.id}`} />}
                <span className="caret" />
              </a>
              <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownAccount">
                <Link className="dropdown-item" to="/Company/Settings">Company Settings</Link>
                <Link className="dropdown-item" to="/Account/Settings">User Settings</Link>
                <Link className="dropdown-item" to="/AgentNotes">Agent Notes</Link>
                <a className="dropdown-item" onClick={this.emailSupport}>Email Support</a>
                <a className="dropdown-item" href="/Account/LogOff">Logout</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); window.location.reload(); }}>Client {version}</a>
              </ul>
            </li>
          </ul>}
        </div>
      </nav>
    </React.Fragment>
  }

  private createSignalRConnection() {

    // Create Connection
    this.connection = new HubConnectionBuilder()
      .withAutomaticReconnect(new LoadOneReconnectPolicy())
      .withUrl("/notifications")
      .configureLogging(LogLevel.Warning)
      .build();

    this.connection.onreconnected(() => {
      this.props.requestAccountNotifications();
    });

    // On New Notification
    this.connection.on('addRange', (notifications: WebNotification[]) => {
      // HTML5 Audio
      for (var i = 0; i < notifications.length; i++) {
        if (notifications[i].Sound) {
          this.setState({ sound: notifications[i].Sound });
          break;
        }
      }
      // HTML5 Notification
      if ('Notification' in window) {
        Notification.requestPermission((status) => {
          if (status !== 'denied') {
            for (var i = 0; i < notifications.length; i++) {
              this.createHtmlNotification(notifications[i]);
            }
          }
        });
      }

      // Update Account Notifications
      this.props.requestAccountNotifications();
    });

    this.connection.on('refresh', () => {
      this.props.requestAccountNotifications();
    });

    // connect
    this.connection.start()
      .catch((error) => {
        console.error(error);
      });
  }

  private createHtmlNotification(notification: WebNotification) {
    new Notification(notification.Text, { tag: notification.Id })
      .onclick = (event) => {
        if (notification.PopupLink) {
          openWindow(notification.PopupLink);
          if (notification.Link)
            window.location.href = notification.Link;
        } else {
          window.focus();
          window.location.href = notification.Link;
        }
      };
  }

  private dismissAllNotifications(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    this.props.accountNotifications.forEach((notification) => {
      const link = notification.Links.find(x => x.Name === 'Dismiss')
      if (link) {
        fetchApi(link.Link, {}, link.Method);
      }
    });
  }

  private emailSupport(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();


    // Create Sentry Issue
    Sentry.withScope((scope) => {
      scope.setLevel('debug');

      const subject = `Support Request - ${this.state.username}`;
      var sentryId = Sentry.captureMessage(subject);
      var sentryUrl = `https://app-nouveau-canada.sentry.io/issues/?project=1825006&query=id%3A${sentryId}`;
      const body = `Please attach any screen shots if possible and indicate any and all identifiers below that apply to this issue that would help us determine the cause:

Module: 
Quote No: 
Order No: 
Truck No: 
Trailer ID: 
Driver ID: 
Owner ID: 
Customer ID: 
Carrier ID: 
Other Identifier: 
Nature of Problem: 
When did problem start occurring: 
Other Relevant Info: 

--------------------

The link below is used to assist tech. support with your issue.  Please do not remove it from this email.
${sentryUrl}`;
      window.location.href = `mailto:app@load1.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    });
  }
}

export default connector(NavBar);
