import * as React from 'react';
import Moment from 'moment-timezone';
import { formatNumber } from '@progress/kendo-intl';
import { SvgIcon } from "@progress/kendo-react-common";
import { rssIcon, starIcon, starOutlineIcon } from '@progress/kendo-svg-icons';
import { MultiSelect, DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { filterBy, orderBy, SortDescriptor, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridSortChangeEvent, GridToolbar, GridSelectionChangeEvent, GridRowProps } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { openWindow } from '../../services/openWindow';
import { ILink } from '../../types/link';
import { AdjustDistance } from './AdjustDistance';
import CarrierOfferButton from './CarrierOfferButton';
import DriverOfferButton from './DriverOfferButton';
import { OfferCarrier } from './OfferCarrier';
import { OfferAllianceCarrier, Carrier } from './OfferAllianceCarrier';
import { OfferMultiCarrier } from './OfferMultiCarrier';
import { OfferGroup } from './OfferGroup';
import Checkbox from '../../components/Checkbox';
import ReservePopup from '../../views/AssetVehicles/ReservePopup';
import './Vehicles.css';

import VehicleNumber from './Vehicles/VehicleNumber';
import VehicleType from './Vehicles/VehicleType';
import VehicleHoursOfService from './Vehicles/HoursOfService';
import VehicleDriverNumber from './Vehicles/DriverNumber';
import VehicleDriverCellPhone1 from './Vehicles/DriverCellPhone1'
import VehicleStatus from './Vehicles/Status';
import dimensionsCell from './Vehicles/DimensionsCell';
import VehiclePositionCell from './Vehicles/PositionCell';
import VehicleLastAvailable from './Vehicles/LastAvailable';
import vehiclePayCell from './Vehicles/PayCell';
import VehicleAvailabilityNote from './Vehicles/AvailabilityNote';
import vehicleReserveCell from './Vehicles/ReserveCell';
import vehicleOfferCell from './Vehicles/OfferCell'
import vehicleActionCell from './Vehicles/ActionPopup';

import AllianceStatus from './AllianceVehicles/Status';
import AllianceSize from './AllianceVehicles/Size';
import allianceCarrierRating from './AllianceVehicles/CarrierRating';
import { Coordinate } from '../../types/address';
import DateCell from '../../components/cells/DateCell';
import { Button } from '@progress/kendo-react-buttons';
import { CarrierTierValue } from 'TypeGen/carrier-tier-value';
import RejectAllButton from './RejectAllButton';
import { dateFormatter } from 'utils/utils';

type State = {
  tabIndex: number;
  showVehicleNotification: boolean;
  showCarrierNotification: boolean;
  reserveVehicleId: number;
  offerCarrierDefaultInput: string;
  offerCarrierIDs: number[];
  offerCarriers: Carrier[];
  vehicleTypes: string[];
  allianceVehicleTypes: string[];
  vehicleSort: SortDescriptor[];
  allianceVehicleSort: SortDescriptor[];
  vehicleFilter: FilterDescriptor[];
  vehicleStatusFilter: FilterDescriptor[];
  CommodityTypeID: number;

  showOfferAllianceCarrier: boolean;
  showOfferMultiCarrier: boolean;
  showOfferCarrier: boolean;
}

type Props = {
  quoteID: number;
  orderID: number | null;
  orderStatus: number | null;
  note: string;
  data: VehicleValue[] | null;
  loading: boolean;
  loadingOffers: boolean;
  defaultFilters: string[];
  sylectusData: AllianceVehicleValue[] | null;
  carrierData: CarrierValue[] | null;
  offerData: OfferValue[] | null;
  fedExData: FedExValue | null;
  dhlData: DHLValue | null;
  eShippingData: EShippingValue | null;
  upsData: UPSValue | null;
  rate: number;
  fuel: number;
  bonus: number;
  margin: number;
  distance: number;
  updateDistance: (newDistance: number) => void;
  dispatch: (vehicleID: number, vehicleNumber: string, plan: boolean) => void;
  cancel: (vehicleID: number) => void;
  offer: (vehicle: VehicleValue, allInRate: number, linehaul: number, fuel: number) => void;
  reoffer: (vehicleID: VehicleValue, allInRate: number, linehaul: number, fuel: number) => void;
  reserve: (vehicleID: number) => void;
  transfer: (vehicleID: number) => void;
  release: (vehicleID: number) => void;
  turndown: (vehicleID: number, driverID: number, distance: number, reset: boolean) => void;
  callLoadOneTrucks: () => void;
  callSylectus: () => void;
  getCarriers: () => void;
  getFedExRates: () => void;
  getDHLRates: () => void;
  getEShippingRates: (commodityTypeID: number) => void;
  getUPSRates: (commodityTypeID: number) => void;
  getOffers: () => Promise<void>;
  openReviews: (vehicle: AllianceVehicleValue | CarrierValue) => void;
  copyEmail: (vehicleID: number) => void;
  viewFit: (vehicleId: number) => void;
  emailCarrier: (offer: OfferValue) => void;
  reply: (offer: OfferValue) => void;
  copyCarrierEmailTemplate: (offer: OfferValue) => void;
  awardCarrier: (offer: OfferValue) => void;
  rejectAllCarriers: () => void;
  openDeadhead: (coordinates: Coordinate) => void;
  selectCarrierOffer?: (total: number, carrierOfferId: number) => void;
};

export type VehicleValue = {
  VehicleID: number;
  VehicleNumber: string;
  ModNum: number;
  IsReserved: boolean;
  IsReservedByMe: boolean;
  Status: string;
  StatusColor: string;
  AvailableDateTime: Date | null;
  EstInServiceDateTime: Date | null;
  LastAvailable: number;
  VehicleType: string;
  VehicleDescription: string;
  PostingClass: string;
  PayLoad: number;
  BoxLength: number;
  BoxWidth: number;
  BoxHeight: number;
  DoorType: string;
  IsTeam: boolean;
  CanCrossBorder: boolean;
  LiftGate: boolean;
  Distance: number;
  PayPercentage: number;
  VehicleTypeID: number;
  DispatchBoardID: number;
  PayrollClassID: string;
  IsAgainstDestination: boolean;
  IsNewHire: boolean;
  Destination: { Location: string, DateTime: Date } | null,

  DispatchNote: string;
  AvailabilityNote: string;
  MPG: number;

  Position: string;
  Coordinates: Coordinate;
  PositionDateTime: Date;

  HoursOfService: string;
  HoursOfServiceDutyStatus: string;
  HoursOfServiceMinutesLeft: number;
  HasHoursRemaining: boolean;
  InServiceBackOn: boolean;
  TransitTimeTooltip: string;
  DriverID: number;
  DriverNumber: string;
  DriverCellPhone1: string;
  NoPet: boolean;

  QuoteOfferAllInRate: number;
  QuoteOfferStatus: number;

  Links: ILink[];

  Hash: string;
}

export type AllianceVehicleValue = {
  VehicleNumber: string;
  Status: string;
  StatusColor: string;
  VehicleType: string;
  PostingClass: string;
  PayLoad: number;
  Dimensions: string;
  Position: string;
  PositionDateTime: Date;
  CarrierName: string;
  CarrierPhone: string;
  Distance: number;
  BackgroundColor: boolean;
  CarrierTier: CarrierTierValue;
  Source: number;
}

export type CarrierValue = {
  CarrierID: number;
  CarrierName: string;
  Location: string;
  CarrierPhone: string;
  Distance: number;
  CarrierTier: CarrierTierValue;
}

export type OfferValue = {
  ID: number;
  OfferID: number;
  AssetName: string;
  VehicleNumber: string;
  IsNew: boolean;
  Phone: string;
  IsCarrier: boolean;
  CarrierTier: CarrierTierValue;
  CarrierOnboarded: boolean;
  OfferDateTime: Date;
  OfferedBy: string;
  AllInRate: number;
  FuelRate?: number;
  Confirmed: boolean;
  Awarded: boolean;
  Withdrawn: boolean;

  // Response
  ResponseSource: string;
  ResponseDateTime: Date | null;
  Status: number;
  AlternativePickup: Date | null;
  AlternativeDelivery: Date | null;
  MilesFromPickup: number | null;
  MinutesLate: number;
  Note: string;
  ContactName: string;
  ContactEmail: string;

  Links: ILink[];
}

export type FedExValue = {
  ErrorMessages: string[];
  Options: Array<{ ServiceLevel: string, Cost: number, Price: number, DeliveryDay: Date }>;
}

export type DHLValue = {
  ErrorMessage: string;
  Options: Array<{ ServiceLevel: string, Cost: number, Price: number, DeliveryDay: Date, DaysInTransit: number }>;
}

export type EShippingValue = {
  ErrorMessage: string;
  Options: Array<{ ServiceLevel: string, Cost: number, Price: number, DeliveryDay: Date, CarrierName: string }>;
}

export type UPSValue = {
  ErrorMessages: string[];
  ServiceOptions: Array<{ ServiceLevel: string, Cost: number, Price: number, DaysInTransit: number }>;
}

const currencyField = {
  paddingRight: "15px",
  textAlign: "right"
} as React.CSSProperties;

const centerDiv = {
  margin: "auto",
  width: "50%",
  textAlign: "center",
  opacity: 0.4,
  fontSize: "14px",
  fontStyle: "italic"
} as React.CSSProperties;

export default class VehiclesPanel extends React.PureComponent<Props, State> {

  private commodityTypes = [
    { value: 0, text: 'Select Freight Class' },
    { value: 50, text: '50 - CLEAN FREIGHT - STANDARD, 4X4 PALLET' },
    { value: 55, text: '55- BRICKS, CEMENT, MORTAR, FLOORING' },
    { value: 60, text: '60 - CAR PARTS/ACCESSORIES' },
    { value: 65, text: '65 - CAR PARTS, BOTTLED BEVERAGES, BOOKS IN BOXES' },
    { value: 70, text: '70 - CAR PARTS/ENGINES, FOOD ITEMS' },
    { value: 77.5, text: '77.5 - TIRES, BATHROOM FIXTURES' },
    { value: 85, text: '85 - CRATED MACHINERY, CAST IRON STOVES' },
    { value: 92.5, text: '92.5 - COMPUTERS, MONITORS, REFIRGERATORS' },
    { value: 100, text: '100 - BOAT/CAR COVERS, CANVAS, WINE CASES, CASKETS' },
    { value: 110, text: '110 - CABINETS, FRAMED ARTWORK, TABLE SAW' },
    { value: 125, text: '125 - SMALL HOUSEHOLD APPLICANCES' },
    { value: 150, text: '150 - AUTO SHEET METAL PARTS, BOOKCASES' },
    { value: 175, text: '175 - CLOTHING, COUCHES STUFFED FURNITURE' },
    { value: 200, text: '200 - AUTO SHEET METAL PARTS, AIRCRAFT PARTS, ALUMINUM TABLE, PACKAGED MATTRESSES' },
    { value: 250, text: '250 - BAMBOO FURNITURE, MATTRESS AND BOX SPRING, PLASMA TV' },
    { value: 300, text: '300 - WOOD CABINETS, TABLES, CHARIS, MODEL BOATS' },
    { value: 400, text: '400 - DEER ANTLERS' },
    { value: 500, text: '500 - BAGS OF GOLD DUST, PING PONG BALLS' }
  ];

  constructor(props: Props) {
    super(props);

    var vehicleFilter: FilterDescriptor[] = [];
    if (this.props.defaultFilters.indexOf("accepted") > -1) {
      vehicleFilter.push({ field: "QuoteOfferStatus", operator: "eq", value: 2 });
    } else if (this.props.defaultFilters.indexOf("team") > -1) {
      vehicleFilter.push({ field: "IsTeam", operator: "eq", value: true });
    }

    this.state = {
      tabIndex: 1,
      showVehicleNotification: false,
      showCarrierNotification: false,
      reserveVehicleId: 0,
      offerCarrierDefaultInput: '',
      offerCarrierIDs: [],
      offerCarriers: [],
      vehicleTypes: [],
      allianceVehicleTypes: [],
      vehicleSort: [{ field: "LastAvailable", dir: "desc" }],
      allianceVehicleSort: [],
      vehicleFilter,
      vehicleStatusFilter: [],
      CommodityTypeID: 0,

      showOfferAllianceCarrier: false,
      showOfferMultiCarrier: false,
      showOfferCarrier: false,
    }

    this.setTab = this.setTab.bind(this);
    this.forceRefresh = this.forceRefresh.bind(this);
    this.doneOfferingMultiple = this.doneOfferingMultiple.bind(this);
    this.hasAndFilter = this.hasAndFilter.bind(this);
    this.filterAnd = this.filterAnd.bind(this);
    this.hasVehicleStatusFilter = this.hasVehicleStatusFilter.bind(this);
    this.filterVehicleStatus = this.filterVehicleStatus.bind(this);
    this.vehicleSortChange = this.vehicleSortChange.bind(this);
    this.allianceVehicleSortChange = this.allianceVehicleSortChange.bind(this);
    this.allianceSelectionChange = this.allianceSelectionChange.bind(this);
    this.handleCommodityTypeChange = this.handleCommodityTypeChange.bind(this);
    this.reservePopup = this.reservePopup.bind(this);
  }

  public render() {
    return (
      <div className="card" style={{ height: '100%', minWidth: 980 }}>
        {this.state.reserveVehicleId > 0 && <ReservePopup
          vehicleId={this.state.reserveVehicleId}
          close={() => this.setState({ reserveVehicleId: 0 })}
          refresh={() => this.setState({ reserveVehicleId: 0 }, this.props.callLoadOneTrucks)}
        />}
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <a className={`nav-link ${this.state.tabIndex === 1 && 'active'}`} href="#" onClick={(e) => this.setTab(e, 1)}>
                Load1 Units{this.state.showVehicleNotification && <React.Fragment>&nbsp;<span className="red-circle" /></React.Fragment>}
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${this.state.tabIndex === 2 && 'active'}`} href="#" onClick={(e) => this.setTab(e, 2)}>Partner Units</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${this.state.tabIndex === 4 && 'active'}`} href="#" onClick={(e) => this.setTab(e, 4)}>Carriers Nearby</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${this.state.tabIndex === 5 && 'active'}`} href="#" onClick={(e) => this.setTab(e, 5)}>FedEx Rates</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${this.state.tabIndex === 6 && 'active'}`} href="#" onClick={(e) => this.setTab(e, 6)}>DHL Rates</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${this.state.tabIndex === 7 && 'active'}`} href="#" onClick={(e) => this.setTab(e, 7)}>EShip Rates</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${this.state.tabIndex === 8 && 'active'}`} href="#" onClick={(e) => this.setTab(e, 8)}>UPS Rates</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${this.state.tabIndex === 9 && 'active'}`} href="#" onClick={(e) => this.setTab(e, 9)}>
                Offers
                {this.state.showCarrierNotification && <React.Fragment>&nbsp;<span className="red-circle" /></React.Fragment>}
              </a>
            </li>
            {this.state.showOfferAllianceCarrier && <OfferAllianceCarrier
              quoteID={this.props.quoteID}
              orderStatus={this.props.orderStatus}
              rate={this.props.rate + this.props.fuel}
              margin={this.props.margin}
              note={this.props.note}
              refresh={this.doneOfferingMultiple}
              onClose={() => this.setState({ showOfferAllianceCarrier: false })}
              carriers={this.state.offerCarriers}
            />}
            {this.state.showOfferMultiCarrier && <OfferMultiCarrier
              quoteID={this.props.quoteID}
              orderStatus={this.props.orderStatus}
              rate={this.props.rate + this.props.fuel}
              margin={this.props.margin}
              note={this.props.note}
              refresh={this.doneOfferingMultiple}
              onClose={() => this.setState({ showOfferMultiCarrier: false })}
              carrierIDs={this.state.offerCarrierIDs}
            />}
            {this.state.showOfferCarrier && <OfferCarrier
              quoteID={this.props.quoteID}
              orderStatus={this.props.orderStatus}
              rate={this.props.rate + this.props.fuel}
              margin={this.props.margin}
              note={this.props.note}
              refresh={this.props.getOffers}
              onClose={() => this.setState({ showOfferCarrier: false })}
            />}
            <li className="nav-item ml-auto">
              <a href="#" className="nav-link border-0" onClick={(e) => { e.preventDefault(); this.setState({ showOfferCarrier: true }); }}>Offer Carriers</a>
            </li>
            {this.state.tabIndex < 5 && <AdjustDistance
              distance={this.props.distance}
              updateDistance={this.props.updateDistance}
            />}
            {this.state.tabIndex === 9 &&
              <OfferGroup
                quoteID={this.props.quoteID}
                orderStatus={this.props.orderStatus}
                rate={this.props.rate + this.props.fuel}
                margin={this.props.margin}
                note={this.props.note}
                refresh={this.props.getOffers}
              />}
          </ul>
        </div>
        {this.state.tabIndex === 1 && this.displayVehicles(this.props.data)}
        {this.state.tabIndex === 2 && this.displayAllianceVehicles(this.props.sylectusData)}
        {this.state.tabIndex === 4 && this.displayCarriers(this.props.carrierData)}
        {this.state.tabIndex === 5 && this.displayFedExRates(this.props.fedExData)}
        {this.state.tabIndex === 6 && this.displayDHLRates(this.props.dhlData)}
        {this.state.tabIndex === 7 && this.displayEShippingRates(this.props.eShippingData)}
        {this.state.tabIndex === 8 && this.displayUPSRates(this.props.upsData)}
        {this.state.tabIndex === 9 && this.displayOffers(this.props.offerData)}
      </div>
    )
  }

  private loadingPanel = () => {
    return <div className="text-center m-4">
      <Loader type="converging-spinner" />
    </div>;
  }

  private displayVehicles(paramData: VehicleValue[] | null) {
    if (this.props.loading) return this.loadingPanel();
    if (!paramData) return <div style={centerDiv}>Location and Truck Size required</div>;
    let data = paramData;

    let mainFilters = [
      { logic: 'and', filters: this.state.vehicleFilter },
      { logic: 'or', filters: this.state.vehicleStatusFilter }
    ] as CompositeFilterDescriptor[];
    mainFilters = mainFilters.filter(x => x.filters.length > 0);
    data = orderBy(filterBy(data, { logic: 'and', filters: mainFilters }), this.state.vehicleSort);
    const vehicleTypes = Array.from(new Set(data.map(x => x.VehicleType)));
    if (this.state.vehicleTypes.length > 0) {
      data = filterBy(data,
        {
          logic: "or",
          filters: this.state.vehicleTypes.map((value) => {
            return {
              field: "VehicleType",
              operator: "eq",
              value,
              ignoreCase: true
            }
          })
        });
    }
    return <React.Fragment>
      <div className="p-2">
        <MultiSelect
          autoClose={false}
          placeholder="Vehicle Types"
          data={vehicleTypes}
          onChange={(event) => this.setState({
            vehicleTypes: [...event.target.value]
          })}
          value={this.state.vehicleTypes} />
      </div>
      <Grid
        sortable={{ allowUnsort: true, mode: "multiple" }}
        scrollable='none'
        sort={this.state.vehicleSort}
        onSortChange={this.vehicleSortChange}
        data={data}
      >
        <GridToolbar>
          <Button
            togglable
            selected={this.hasAndFilter('IsTeam', 'eq', false)}
            onClick={() => this.filterAnd('IsTeam', 'eq', false)}
          >Solo Only
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('IsTeam', 'eq', true)}
            onClick={() => this.filterAnd('IsTeam', 'eq', true)}
          >Teams Only
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('CanCrossBorder', 'eq', true)}
            onClick={() => this.filterAnd('CanCrossBorder', 'eq', true)}
          >Can Cross Border
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('LiftGate', 'eq', true)}
            onClick={() => this.filterAnd('LiftGate', 'eq', true)}
          >Liftgate
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('LiftGate', 'eq', false)}
            onClick={() => this.filterAnd('LiftGate', 'eq', false)}
          >No Liftgate
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('DoorType', 'eq', 'R')}
            onClick={() => this.filterAnd('DoorType', 'eq', 'R')}
          >Roll Up
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('DoorType', 'eq', 'B')}
            onClick={() => this.filterAnd('DoorType', 'eq', 'B')}
          >Barn
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('NoPet', 'eq', true)}
            onClick={() => this.filterAnd('NoPet', 'eq', true)}
          >No Pets
          </Button>
          <Button
            togglable
            selected={this.hasVehicleStatusFilter([{ field: 'Status', operator: 'endswith', value: 'In Service' }])}
            onClick={() => this.filterVehicleStatus([{ field: 'Status', operator: 'endswith', value: 'In Service' }, { field: 'Status', operator: 'eq', value: 'Repositioning' }])}
          >In Service
          </Button>
          <Button
            togglable
            selected={this.hasVehicleStatusFilter([{ field: 'Status', operator: 'eq', value: 'On A Load' }])}
            onClick={() => this.filterVehicleStatus([{ field: 'Status', operator: 'eq', value: 'On A Load' }])}
          >On A Load
          </Button>
          <Button
            togglable
            selected={this.hasVehicleStatusFilter([{ field: 'Status', operator: 'eq', value: 'Out Of Service' }])}
            onClick={() => this.filterVehicleStatus([{ field: 'Status', operator: 'eq', value: 'Out Of Service' }])}
          >Out Of Service
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('HasHoursRemaining', 'eq', true)}
            onClick={() => this.filterAnd('HasHoursRemaining', 'eq', true)}
          >Within HOS Limits
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('MPG', 'eq', 4)}
            onClick={() => this.filterAnd('MPG', 'eq', 4)}
            title="MPG=4"
          >Local
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('MPG', 'eq', 3)}
            onClick={() => this.filterAnd('MPG', 'eq', 3)}
            title="MPG=3"
          >Regional
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('MPG', 'eq', 2)}
            onClick={() => this.filterAnd('MPG', 'eq', 2)}
            title="MPG=2"
          >Single Longhaul
          </Button>
          <Button
            togglable
            selected={this.hasAndFilter('QuoteOfferStatus', 'eq', 2)}
            onClick={() => this.filterAnd('QuoteOfferStatus', 'eq', 2)}
            title="Offer Accepted"
          >Accepted
          </Button>
        </GridToolbar>
        <Column field="VehicleNumber" title="Unit #" cell={VehicleNumber} />
        <Column field="DriverNumber" title="Driver" cell={VehicleDriverNumber} />
        <Column field="DriverCellPhone1" title="Driver Cell" cell={VehicleDriverCellPhone1} />
        <Column field="HoursOfService" title="HOS" cell={VehicleHoursOfService} />
        <Column field="Status" cell={VehicleStatus} />
        <Column field="VehicleType" title="Size" cell={VehicleType} />
        <Column field="PostingClass" title="Class" />
        <Column field="PayLoad" filter="numeric" />
        <Column field="BoxLength" title="LxWxH" cell={dimensionsCell(this.props.viewFit)} />
        <Column field="Position" cell={VehiclePositionCell} />
        <Column field="LastAvailable" title="LA" filter="numeric" cell={VehicleLastAvailable} />
        <Column field="Distance" format="{0:n0}" filter="numeric" />
        <Column field="PayPercentage" title="Pay" cell={vehiclePayCell(this.props.rate, this.props.fuel, this.props.bonus)} />
        <Column field="AvailabilityNote" title="Note" cell={VehicleAvailabilityNote} />
        <Column field="HaulingCategory" title="Hauling Category" />
        <Column field="IsReserved" title="Reserve" cell={vehicleReserveCell(this.props.reserve, this.reservePopup)} />
        <Column title="Offer" cell={vehicleOfferCell(this.props.rate, this.props.fuel, this.props.bonus, this.props.cancel, this.props.offer)} sortable={false} />
        <Column title="Action" cell={vehicleActionCell(
          this.props.rate,
          this.props.fuel,
          this.props.orderStatus,
          this.props.dispatch,
          this.props.reoffer,
          this.props.turndown,
          this.props.transfer,
          this.props.release,
          this.props.copyEmail,
          this.props.openDeadhead
        )} sortable={false} />
      </Grid>
    </React.Fragment>
  }

  private displayCarriers(data: CarrierValue[] | null) {
    if (this.props.loading) return this.loadingPanel();
    if (!data) return <div style={centerDiv}>Location required</div>;

    return <React.Fragment>
      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
            <th>Phone</th>
            <th>Rating</th>
            <th>Distance</th>
            <th>
              <Button
                disabled={this.state.offerCarrierIDs.length === 0}
                themeColor="primary"
                onClick={() => this.setState({ showOfferMultiCarrier: true })}
              >
                Offer
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((carrier, index) => {
            return (<tr key={index}>
              <td><a href="#" onClick={(e) => this.openCarrierProfile(e, carrier.CarrierID)}>{carrier.CarrierName}</a></td>
              <td>{carrier.Location}</td>
              <td>{carrier.CarrierPhone}</td>
              <td>
                {carrier.CarrierTier === CarrierTierValue.Platinum ? <SvgIcon icon={starIcon} svgStyle={{ fill: "url(#platinum-carrier-gradient)" }} />
                  : carrier.CarrierTier === CarrierTierValue.Gold ? <SvgIcon icon={starIcon} color="#ecb73d" />
                    : carrier.CarrierTier === CarrierTierValue.Silver ? <SvgIcon icon={starIcon} color="#9B9B9B" />
                      : <SvgIcon icon={starOutlineIcon} color="#9B9B9B" />}
              </td>
              <td>{formatNumber(carrier.Distance, "n0")} mi</td>
              <td className="text-center"><Checkbox id={`offer${carrier.CarrierID}`} className="form-check-input" value={carrier.CarrierID.toString()} handleCheckboxChange={(value, isChecked) => this.offerCarrierId(parseInt(value), isChecked)} /></td>
            </tr>)
          })}
        </tbody>
      </table>
    </React.Fragment>
  }

  private displayAllianceVehicles(paramData: AllianceVehicleValue[] | null) {
    if (this.props.loading) return this.loadingPanel();
    if (!paramData) return <div style={centerDiv}>Location required</div>;
    let data = paramData;

    const vehicleTypes = Array.from(new Set(data.map(x => x.VehicleType)));
    if (this.state.allianceVehicleTypes.length > 0) {
      data = filterBy(data,
        {
          logic: "or",
          filters: this.state.allianceVehicleTypes.map((value) => {
            return {
              field: "VehicleType",
              operator: "eq",
              value,
              ignoreCase: true
            }
          })
        });
    }
    data = orderBy(data, this.state.allianceVehicleSort);
    return <React.Fragment>
      <div className="p-2">
        <MultiSelect
          autoClose={false}
          placeholder="Vehicle Types"
          data={vehicleTypes}
          onChange={(event) => this.setState({
            allianceVehicleTypes: [...event.target.value]
          })}
          value={this.state.allianceVehicleTypes} />
      </div>
      <Grid
        selectedField="selected"
        scrollable="none"
        onSelectionChange={this.allianceSelectionChange}
        sortable={{ allowUnsort: true, mode: "single" }}
        sort={this.state.allianceVehicleSort}
        onSortChange={this.allianceVehicleSortChange}
        rowRender={this.allianceRowRender}
        data={data.map((item) => ({
          ...item,
          selected: this.state.offerCarriers.filter(x => x.name == item.CarrierName).length > 0,
        }))}
      >
        <Column field="VehicleNumber" title="Unit #" cell={({ dataItem: vehicle }) => <td title={vehicle.Source === 4 ? 'App Tracked' : vehicle.Source === 3 ? 'Tri-State' : vehicle.Source === 2 ? 'Full Circle' : 'Sylectus'}>
          {vehicle.VehicleNumber}{vehicle.Source === 4 && <SvgIcon icon={rssIcon} color="#ecb73d" className='ml-1' />}
        </td>} />
        <Column field="Status" cell={AllianceStatus} />
        <Column field="VehicleType" title="Size" cell={AllianceSize} />
        <Column field="PayLoad" title="Payload" format="{0:n0}" filter="numeric" />
        <Column field="Dimensions" title="LxWxH" />
        <Column field="Position" />
        <Column field="PositionDateTime" title="Pos. Date Time" filter="date" cell={DateCell} />
        <Column field="Distance" format="{0:n0} mi" filter="numeric" />
        <Column field="CarrierPhone" title="Phone" />
        <Column field="CarrierName" title="Carrier" />
        <Column field="CarrierTier" title="Tier" cell={allianceCarrierRating(this.props.openReviews)} />
        <Column
          field="selected"
          width="60px"
          headerCell={(props) => <Button
            disabled={this.state.offerCarriers.length === 0}
            themeColor="primary"
            onClick={() => this.setState({ showOfferAllianceCarrier: true })}
          >
            Offer
          </Button>}
        />
      </Grid>
    </React.Fragment>
  }

  private allianceRowRender(trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) {
    const trProps = props.dataItem.BackgroundColor ? { style: { backgroundColor: props.dataItem.BackgroundColor } as CSSStyleDeclaration } : {};
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children as React.ReactNode);
  }

  private offerRowRender(trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) {
    const trProps = props.dataItem.Withdrawn ? { style: { opacity: '0.5' } as CSSStyleDeclaration } : {};
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children as React.ReactNode);
  }

  private displayOffers(data: OfferValue[] | null) {
    if (this.props.loadingOffers) return this.loadingPanel();
    if (!data || data.length === 0) return <div style={centerDiv}>No offers found</div>;

    return <Grid data={data} scrollable="none" rowRender={this.offerRowRender}>
      <Column field="ID" title="Name" cell={({ dataItem: offer }) => <td>
        {offer.IsCarrier && <>
          <a href="#" style={{ color: offer.CarrierOnboarded ? '#007bff' : '#dc3545' }} onClick={(e) => this.openCarrierProfile(e, offer.ID)}>{offer.AssetName}</a>
          {offer.CarrierTier === CarrierTierValue.Platinum ? <SvgIcon icon={starIcon} svgStyle={{ fill: "url(#platinum-carrier-gradient)" }} />
            : offer.CarrierTier === CarrierTierValue.Gold ? <SvgIcon icon={starIcon} color="#ecb73d" />
            : offer.CarrierTier === CarrierTierValue.Silver ? <SvgIcon icon={starIcon} color="#9B9B9B" /> : null}
        </>}
        {offer.IsCarrier === false && <a href="#" style={{ color: '#007bff' }} onClick={(e) => this.openDriverProfile(e, offer.ID)}>{offer.VehicleNumber} - {offer.AssetName}</a>}
        {offer.IsNew && <> <span className="badge badge-warning">NEW</span></>}
      </td>} />
      <Column field="OfferDateTime" title="Offered At" cell={({ dataItem: offer }) => <td title={offer.OfferedBy}>
        {dateFormatter(offer.OfferDateTime)}
      </td>} />
      <Column field="Phone" title="Phone" cell={({ dataItem: offer }) => <td>
        {offer.IsCarrier && <a href="#" style={{ color: '#007bff' }} onClick={(e) => this.openCarrierContacts(e, offer.ID)}>{offer.Phone}</a>}
        {offer.IsCarrier === false && offer.Phone}
      </td>} />
      <Column field="FuelRate" title="Fuel Surcharge" format="{0:c}" />
      <Column field="ResponseSource" title="Reply From" cell={({ dataItem: offer }) => <td title={offer.ResponseDateTime ? dateFormatter(offer.ResponseDateTime) : ''}>
        {offer.ResponseSource}
      </td>} />
      <Column field="MilesFromPickup" title="Miles Out" format="{0:n0}" cell={(e) => <td>
        {e.dataItem.MilesFromPickup && <span className={e.dataItem.MinutesLate > 0 ? 'text-danger' : 'text-success'} title={e.dataItem.MinutesLate > 0 ? `Late: ${e.dataItem.MinutesLate} min.` : ''}>
          {formatNumber(e.dataItem.MilesFromPickup, "n0")} mi
        </span>}
      </td>} />
      <Column
        field="Status"
        headerCell={() => <RejectAllButton disabled={!data.some(x => x.IsCarrier)} rejectAll={this.props.rejectAllCarriers} />}
        cell={({ dataItem: offer }) => <td>
          {offer.IsCarrier && <CarrierOfferButton
            quoteID={this.props.quoteID}
            offer={offer}
            email={this.props.emailCarrier}
            reply={this.props.reply}
            copyTemplate={this.props.copyCarrierEmailTemplate}
            award={this.props.awardCarrier}
            reOffer={() => this.setState({ showOfferMultiCarrier: true, offerCarrierIDs: [offer.ID] })}
            offerSelected={this.props.selectCarrierOffer}
          />}
          {offer.IsCarrier === false && <DriverOfferButton quoteID={this.props.quoteID} driver={offer}
          />}
        </td>} />
    </Grid>;
  }

  private displayFedExRates(data: FedExValue | null) {
    if (this.props.loading) return this.loadingPanel();
    if (!data) return <div style={centerDiv}>Location, pieces and weight are required</div>;

    return <React.Fragment>
      {data.ErrorMessages && data.ErrorMessages.map((errorMessage) => {
        return <div className="alert alert-warning" role="alert">{errorMessage}</div>;
      })}
      {!data.ErrorMessages && !data.Options && <div style={centerDiv}>Location, pieces and weight are required</div>}

      {data.Options && <table className="table table-sm table-striped">
        <colgroup>
          <col width="32%" />
          <col width="22%" />
          <col width="18%" />
          <col width="18%" />
          <col width="10%" />
        </colgroup>
        <thead>
          <tr>
            <th>FedEx</th>
            <th>Delivery</th>
            <th style={currencyField}>Cost</th>
            <th style={currencyField}>Price</th>
            <th style={currencyField}>Margin</th>
          </tr>
        </thead>
        <tbody>
          {data.Options.map((x, index) =>
            <tr key={index}>
              <td>{x.ServiceLevel}</td>
              <td>{Moment(x.DeliveryDay).format("MM/DD/YYYY HH:mm")}</td>
              <td style={currencyField}>{formatNumber(x.Cost, "c")}</td>
              <td style={currencyField}>{formatNumber(x.Price, "c")}</td>
              <td style={currencyField}>{formatNumber((x.Price - x.Cost) / x.Price, "p")}</td>
            </tr>)}
        </tbody>
      </table>}
    </React.Fragment>
  }

  private displayDHLRates(data: DHLValue | null) {
    if (this.props.loading) return this.loadingPanel();
    if (!data) return <div style={centerDiv}>Location, pieces and weight are required</div>;

    return <React.Fragment>
      {(!data.ErrorMessage && !data.Options) ? <div style={centerDiv}>Location, pieces and weight are required</div> : ""}
      {(data.ErrorMessage && data.ErrorMessage.length > 0) ? <div className="alert alert-warning" role="alert">{data.ErrorMessage}</div> : ""}
      {data.Options && <table className="table table-sm table-striped">
        <colgroup>
          <col width="32%" />
          <col width="22%" />
          <col width="8%" />
          <col width="14%" />
          <col width="14%" />
          <col width="10%" />
        </colgroup>
        <thead>
          <tr>
            <th>DHL</th>
            <th>Delivery</th>
            <th style={{ textAlign: "center" }}>Days In Transit</th>
            <th style={currencyField}>Cost</th>
            <th style={currencyField}>Price</th>
            <th style={currencyField}>Margin</th>
          </tr>
        </thead>
        <tbody>
          {data.Options.map((x, index) =>
            <tr key={index}>
              <td>{x.ServiceLevel}</td>
              <td>{Moment(x.DeliveryDay).format("MM/DD/YYYY")}</td>
              <td style={{ textAlign: "center" }}>{x.DaysInTransit}</td>
              <td style={currencyField}>{formatNumber(x.Cost, "c")}</td>
              <td style={currencyField}>{formatNumber(x.Price, "c")}</td>
              <td style={currencyField}>{formatNumber((x.Price - x.Cost) / x.Price, "p")}</td>
            </tr>)}
        </tbody>
      </table>}
    </React.Fragment>
  }

  private reservePopup(vehicleId: number) {
    this.setState({ reserveVehicleId: vehicleId });
  }

  private handleCommodityTypeChange(e: DropDownListChangeEvent) {
    this.setState({
      CommodityTypeID: e.target.value.value
    });
    if (this.state.tabIndex == 7)
      this.props.getEShippingRates(e.target.value.value as number);
    if (this.state.tabIndex == 8)
      this.props.getUPSRates(e.target.value.value as number);
  }

  private CommodityDropDown() {
    return <React.Fragment>
      <div className="form-row" style={{ display: "block", marginTop: "5px", marginLeft: "1px", marginBottom: "5px" }}>
        <div className="col-md-3">
          <DropDownList
            className="form-control"
            defaultValue={0}
            value={this.commodityTypes.find(x => x.value == this.state.CommodityTypeID)}
            popupSettings={{ height: 700, width: 500 }}
            data={this.commodityTypes}
            textField="text"
            onChange={this.handleCommodityTypeChange}
          />
        </div>
      </div>
    </React.Fragment>
  }

  private displayEShippingRates(data: EShippingValue | null) {
    if (this.props.loading) return this.loadingPanel();
    if (!data) {
      return <React.Fragment>
        {this.CommodityDropDown()}
        <div style={centerDiv}>Location, pieces, weight and freight class are required</div>
      </React.Fragment>
    }

    return <React.Fragment>
      {this.CommodityDropDown()}
      {(!data.ErrorMessage && !data.Options) ? <div style={centerDiv}>Location, pieces, weight and freight class are required</div> : ""}
      {(data.ErrorMessage && data.ErrorMessage.length > 0) ? <div className="alert alert-warning" role="alert">{data.ErrorMessage}</div> : ""}
      {data.Options && <div className="form-row"><div className="col-md-12">
        <table className="table table-sm table-striped">
          <colgroup>
            <col width="30%" />
            <col width="10%" />
            <col width="30%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th>EShip</th>
              <th>Delivery</th>
              <th>Carrier Name</th>
              <th style={currencyField}>Cost</th>
              <th style={currencyField}>Price</th>
              <th style={currencyField}>Margin</th>
            </tr>
          </thead>
          <tbody>
            {data.Options.map((x, index) =>
              <tr key={index}>
                <td>{x.ServiceLevel}</td>
                <td>{Moment(x.DeliveryDay).format("MM/DD/YYYY")}</td>
                <td>{x.CarrierName}</td>
                <td style={currencyField}>{formatNumber(x.Cost, "c")}</td>
                <td style={currencyField}>{formatNumber(x.Price, "c")}</td>
                <td style={currencyField}>{formatNumber((x.Price - x.Cost) / x.Price, "p")}</td>
              </tr>)}
          </tbody>
        </table>
      </div>
      </div>}
    </React.Fragment>
  }

  private displayUPSRates(data: UPSValue | null) {
    if (this.props.loading) return this.loadingPanel();
    if (!data) {
      return <React.Fragment>
        {this.CommodityDropDown()}
        <div style={centerDiv}>Location, pieces, weight and freight class are required</div>
      </React.Fragment>
    }

    return <React.Fragment>
      {this.CommodityDropDown()}
      {data.ErrorMessages && data.ErrorMessages.map((errorMessage) => {
        return <div className="alert alert-warning" role="alert">{errorMessage}</div>;
      })}
      {!data.ErrorMessages && !data.ServiceOptions && <div style={centerDiv}>Location, pieces and weight are required</div>}

      {data.ServiceOptions && <div className="form-row"><div className="col-md-12">
        <table className="table table-sm table-striped">
          <colgroup>
            <col width="30%" />
            <col width="10%" />
            <col width="30%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th>UPS</th>
              <th style={currencyField}>Price</th>
              <th style={{ textAlign: "center" }}>Days In Transit</th>
            </tr>
          </thead>
          <tbody>
            {data.ServiceOptions.map((x, index) =>
              <tr key={index}>
                <td>{x.ServiceLevel}</td>
                {/**<td style={currencyField}>{formatNumber(x.Cost, "c")}</td>
                                <td style={currencyField}>{formatNumber((x.Price - x.Cost) / x.Price, "p")}</td>**/}
                <td style={currencyField}>{formatNumber(x.Price, "c")}</td>
                <td style={{ textAlign: "center" }}>{x.DaysInTransit}</td>
              </tr>)}
          </tbody>
        </table>
      </div>
      </div>}
    </React.Fragment>
  }

  private openCarrierProfile(e: any, carrierId: number) {
    e.preventDefault();

    openWindow(`/Assets/Carrier/${carrierId}`);
  }

  private openDriverProfile(e: any, driverId: number) {
    e.preventDefault();

    openWindow("/Assets/Driver/" + driverId);
  }


  private openCarrierContacts(e: any, carrierId: number) {
    e.preventDefault();

    openWindow(`/Assets/Carrier/${carrierId}/Contacts`);
  }

  private setTab(e: any, tabIndex: number) {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      tabIndex,
      offerCarrierIDs: [],
      offerCarriers: [],
      showVehicleNotification: tabIndex === 1 ? false : this.state.showVehicleNotification,
      showCarrierNotification: tabIndex === 9 ? false : this.state.showCarrierNotification
    });


    if (tabIndex == 1 && this.props.data == null) {
      this.props.callLoadOneTrucks();
    }

    if (tabIndex == 2 && this.props.sylectusData == null) {
      this.props.callSylectus();
    }

    if (tabIndex == 4 && this.props.carrierData == null) {
      this.props.getCarriers();
    }

    if (tabIndex == 5 && this.props.fedExData == null) {
      this.props.getFedExRates();
    }

    if (tabIndex == 6 && this.props.dhlData == null) {
      this.props.getDHLRates();
    }

    if (tabIndex == 7 && this.props.eShippingData == null) {
      this.props.getEShippingRates(this.state.CommodityTypeID);
    }

    if (tabIndex == 8 && this.props.upsData == null) {
      this.props.getUPSRates(this.state.CommodityTypeID);
    }

    if (tabIndex == 9 && this.props.offerData == null) {
      this.props.getOffers();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.quoteID > 0 && this.props.quoteID != prevProps.quoteID) {
      this.setState({
        tabIndex: 1,
        showVehicleNotification: false,
        showCarrierNotification: false,
        vehicleTypes: [],
        allianceVehicleTypes: [],
        vehicleSort: [{ field: "LastAvailable", dir: "desc" }],
        allianceVehicleSort: [],
      })
    }

    if ((!this.props.sylectusData && prevProps.sylectusData && this.state.tabIndex === 2)
      || (!this.props.carrierData && prevProps.carrierData && this.state.tabIndex === 4)
      || (!this.props.fedExData && prevProps.fedExData && this.state.tabIndex === 5)
      || (!this.props.dhlData && prevProps.dhlData && this.state.tabIndex === 6)
      || (!this.props.eShippingData && prevProps.eShippingData && this.state.tabIndex === 7)
      || (!this.props.upsData && prevProps.upsData && this.state.tabIndex === 8)
      || (!this.props.offerData && prevProps.offerData && this.state.tabIndex === 9)) {
      this.setState({
        showVehicleNotification: false,
        showCarrierNotification: false,
        offerCarrierIDs: [],
        offerCarriers: [],
      });
    }
  }

  public forceRefresh() {
    if (this.state.tabIndex == 1 && this.props.data == null) {
      this.props.callLoadOneTrucks();
    }
    if (this.state.tabIndex == 2 && this.props.sylectusData == null) {
      this.props.callSylectus();
    }
    if (this.state.tabIndex == 4 && this.props.carrierData == null) {
      this.props.getCarriers();
    }
    if (this.state.tabIndex == 5 && this.props.fedExData == null) {
      this.props.getFedExRates();
    }
    if (this.state.tabIndex == 6 && this.props.dhlData == null) {
      this.props.getDHLRates();
    }
    if (this.state.tabIndex == 7 && this.props.eShippingData == null) {
      this.props.getEShippingRates(this.state.CommodityTypeID);
    }
    if (this.state.tabIndex == 8 && this.props.upsData == null) {
      this.props.getUPSRates(this.state.CommodityTypeID);
    }
    if (this.state.tabIndex == 9 && this.props.offerData == null) {
      this.props.getOffers();
    }
  }

  private doneOfferingMultiple() {
    this.props.getOffers()
      .then(() => {
        this.setTab(null, 9);
      })
  }

  private offerCarrierId(carrierId: number, add: boolean) {
    if (add) {
      this.setState(prevState => ({ offerCarrierIDs: [...prevState.offerCarrierIDs.filter(x => x !== carrierId), carrierId] }));
    } else {
      this.setState(prevState => ({ offerCarrierIDs: prevState.offerCarrierIDs.filter(x => x !== carrierId) }));
    }
  }

  private offerCarrierName(carrier: Carrier, add: boolean) {
    if (add) {
      this.setState(prevState => ({ offerCarriers: [...prevState.offerCarriers.filter(x => x.name !== carrier.name), carrier] }));
    } else {
      this.setState(prevState => ({ offerCarriers: prevState.offerCarriers.filter(x => x.name !== carrier.name) }));
    }
  }

  private hasAndFilter(field: string, operator: string = 'eq', value: any = true): boolean {
    return this.state.vehicleFilter
      .filter(x => x.field === field && x.operator === operator && x.value === value)
      .length > 0;
  }

  private filterAnd(field: string, operator: string = 'eq', value: any = true) {
    const enabled = this.hasAndFilter(field, operator, value);
    const existingFilter = this.state.vehicleFilter.filter(x => x.field !== field);
    if (enabled) {
      this.setState({ vehicleFilter: existingFilter });
    } else {
      this.setState({ vehicleFilter: [...existingFilter, { field: field, operator: operator, value: value }] as FilterDescriptor[] });
    }
  }

  private hasVehicleStatusFilter(filters: FilterDescriptor[]): boolean {
    return this.state.vehicleStatusFilter
      .filter(x => filters.filter(filter => x.field === filter.field && x.operator === filter.operator && x.value === filter.value).length > 0)
      .length > 0;
  }

  private filterVehicleStatus(filters: FilterDescriptor[]) {
    const enabled = this.hasVehicleStatusFilter(filters);
    const existingFilter = this.state.vehicleStatusFilter
      .filter(x => filters.filter(filter => x.field === filter.field && x.operator === filter.operator && x.value !== filter.value).length > 0);

    if (enabled) {
      this.setState({ vehicleStatusFilter: existingFilter });
    } else {
      this.setState({ vehicleStatusFilter: [...existingFilter, ...filters] });
    }
  }

  private vehicleSortChange(event: GridSortChangeEvent) {
    this.setState({
      vehicleSort: event.sort
    });
  }

  private allianceVehicleSortChange(event: GridSortChangeEvent) {
    this.setState({
      allianceVehicleSort: event.sort
    });
  }

  private allianceSelectionChange(event: GridSelectionChangeEvent) {
    const checked = event.nativeEvent.target.checked;
    this.offerCarrierName({ name: event.dataItem.CarrierName, phone: event.dataItem.CarrierPhone, vehicleNumbers: [event.dataItem.VehicleNumber] }, checked);
  }

  public newQuoteOffer(quoteOfferType: number) {
    if (this.state.tabIndex !== quoteOfferType) {
      if (quoteOfferType === 1) {
        this.setState({ showVehicleNotification: true });
      }
      if (quoteOfferType === 9) {
        this.setState({ showCarrierNotification: true });
      }
    }
  }
}
