import { debounce } from 'ts-debounce';
import * as JsSearch from 'js-search';
import * as React from 'react';
import { Title } from '../../utils/title';
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridFilterChangeEvent,
  GridExpandChangeEvent,
  GridColumnReorderEvent
} from '@progress/kendo-react-grid';
import Moment from 'moment';
import { visibility, isVisible } from '../../services/visibility';
import { fetchApi } from '../../services/api';
import TrackDetailRow from './DetailRow';
import ReferenceCell from './ReferenceCell';
import { PickupCell } from './PickupCell';
import { DeliveryCell } from './DeliveryCell';
import AssetCell from './AssetCell';
import HOSCell from './HOSCell';
import CustomerCell from './CustomerCell';
import NextStopCell from './NextStopCell';
import PositionCell from './PositionCell';
import ETACell from './ETACell';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor
} from '@progress/kendo-data-query';
import StatusCell from './StatusCell';
import IconCell from './IconCell';
import { SpeedCell } from './SpeedCell';
import { ActionCell } from './ActionCell';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { ComposeEmail } from './ComposeEmail';
import { SetupNotifications } from './SetupNotifications';
import AdvancedSearch from './AdvancedSearch';
import { OrderAuditLogGrid } from './OrderAuditLogGrid';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { ILink } from '../../types/link';
import { Input } from '@progress/kendo-react-inputs';
import { arrowRotateCwIcon, filterClearIcon, zoomInIcon } from '@progress/kendo-svg-icons';

export interface Trip {
  OrderID: number | null;
  OrderNumber: number | null;
  TripID: number;
  TripNumber: number;
  ReferenceNumber1: string;
  ReferenceNumber2: string;
  ReferenceNumber3: string;
  ReferenceNumber4: string;
  LaneName: string;
  StatusNote: string;
  ServiceTeamID: number | null;
  OrderStatusName: string | null;
  OrderStopStatus: number | null;
  OrderStopEvent: number | null;
  AuthorizationCustomerID: string | null;
  AuthorizationCustomerNumber: string;
  AssetID: number;
  AssetName: string;
  TrailerID: number;
  TrailerName: string;
  CarrierPhone: string;
  DriverPhone: string;
  VehicleTypeName: string;
  VehicleSpeed: number;
  PickupLocation: string;
  PickupDateTime: Date;
  DeliveryLocation: string;
  Verb: string;
  DeliveryDateTime: Date;
  ScheduledDateTime?: Date;
  EarliestStopDateTime?: Date | null;
  LatestStopDateTime?: Date | null;
  PositionLocation: string;
  PositionDateTime: Date;
  PositionRepeatCount: number;
  ETADateTime: Date;
  StopCount: number;
  StopSequence: number;
  RequiredSpeed: number;
  SplitLabel: string;
  Drivers: TripDriver[];

  IsBrokered: boolean;
  IsRunningLate: boolean;
  IsScheduledSoon: boolean;
  TimeCriticalMinutes: number;
  IsCritical: boolean | null;
  NeedsCheckCall: boolean;
  WaitingAtArrivedMinutes: number;
  HasNotificationsSetup: boolean;
  HasEventNotification: boolean;
  ExternalUrl: string;
  HazMat: boolean;
  DriverAppCarrierHours: number;
  CarrierThreadID: string;
  CarrierThreadLastEmailedDateTime: string;
  ResumeDrivingDateTime: string | null;
  DrivingBreakComment: string | null;
  DrivingBreakDoNotDisturb: boolean | null;

  Links: ILink[];
  Hash: string;

  expanded: boolean;
}

export interface TripDriver {
  DriverNumber: string;
  DriverPhone: string;
  DriverID: number;
  Status: string;
  MinutesLeft: number;
  Formatted: string;
  DoNotCall?: boolean;
  Links: ILink[];
}

type Props = RouteComponentProps<{
  searchTerm: string;
}>;

type State = {
  advancedSearchPopup: boolean;
  advancedSearchQuery: null | AdvancedSearchQuery;
  composeLink: null | ILink;
  drivers: any[];
  data: null | Trip[];
  loading: boolean;
  backgroundMode: boolean;
  filter: CompositeFilterDescriptor;
  filterText: string;
  orFilter: FilterDescriptor[];
  andFilter: FilterDescriptor[];
  setupLink: null | ILink;
  orderAuditLink: null | ILink;
  serviceTeams: number[];
  dispatchTeams: string[];
  myFreight: boolean;
  deadhead: boolean;
  columnOrder: string[];
  sort: SortDescriptor[];
  trips: null | Trip[];
  vehicles: Array<{ ID: number; Name: string }>;
}

export type AdvancedSearchQuery = {
  OrderStatuses: number[];
  DispatchStatuses: number[];
  SearchDateType: number;
  FromDate: Date;
  ToDate: Date;
  IgnoreServiceTeams: boolean;
  SearchOptionType: number;
  SearchOptionValue: string;
  FromState: string;
  ToState: string;
  AssetID: number;
  AssetType: number;
  VehicleTypeID: number;
  ServiceTeams: number[];
  MyFreight: boolean;
  BookUserIDs: number[];
  CustomerID: number;
  CustomerNumber: string;
  CustomerType: number;
  CopiedOrderNumber: number;
}

const TrackContext = React.createContext<{
  refresh: () => Promise<void>;
}>({
  refresh: async () => { }
})

export const useTrack = () => React.useContext(TrackContext);

const defaultColumnOrder = [
  "OrderNumber",
  "PickupDateTime",
  "DeliveryDateTime",
  "OrderStopStatus",
  "AssetName",
  "HoursOfService",
  "AuthorizationCustomerNumber",
  "ScheduledDateTime",
  "ETADateTime",
  "PositionDateTime",
  "ETAMiles",
  "Share",
  "Action"
];

class Track extends React.Component<Props, State> {

  private search: JsSearch.Search;
  private refreshInterval: NodeJS.Timeout;
  private backgroundTimeout: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);

    this.sortChange = this.sortChange.bind(this);
    this.availableFilter = this.availableFilter.bind(this);
    this.committedFilter = this.committedFilter.bind(this);
    this.myCommittedFilter = this.myCommittedFilter.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.filterAnd = this.filterAnd.bind(this);
    this.filterTextChange = this.filterTextChange.bind(this);
    this.filterClear = this.filterClear.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.toggleAdvancedSearch = this.toggleAdvancedSearch.bind(this);
    this.onAdvancedSearchChange = this.onAdvancedSearchChange.bind(this);
    this.expandChange = this.expandChange.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.enterBackgroundMode = this.enterBackgroundMode.bind(this);
    this.columnReorder = this.columnReorder.bind(this);

    let andFilter = [];
    let orFilter = [];
    let sort = [];
    let columnOrder = defaultColumnOrder;

    const sessionStorageAndFilter = sessionStorage.getItem("Track-andFilter");
    if (sessionStorageAndFilter) andFilter = JSON.parse(sessionStorageAndFilter);

    const sessionStorageOrFilter = sessionStorage.getItem("Track-orFilter");
    if (sessionStorageOrFilter) orFilter = JSON.parse(sessionStorageOrFilter);

    const sessionStorageSort = sessionStorage.getItem("Track-sort");
    if (sessionStorageSort) sort = JSON.parse(sessionStorageSort);

    const localStorageColumnOrder = localStorage.getItem("Track-columnOrder");
    if (localStorageColumnOrder) {
      const localStorageColumnOrderArray = JSON.parse(localStorageColumnOrder) as string[];
      if (columnOrder.every((x) => localStorageColumnOrderArray.includes(x))) {
        columnOrder = JSON.parse(localStorageColumnOrder) as string[];
      } else {
        localStorage.removeItem("Track-columnOrder");
      }
    }

    const initialFilter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [
        {
          logic: 'or',
          filters: orFilter
        } as CompositeFilterDescriptor,
        {
          logic: 'and',
          filters: andFilter
        } as CompositeFilterDescriptor
      ].filter((x) => x.filters.length)
    }

    this.state = {
      advancedSearchPopup: false,
      advancedSearchQuery: null,
      composeLink: null,
      data: null,
      drivers: [],
      loading: true,
      backgroundMode: false,
      filter: initialFilter,
      filterText: props.match.params.searchTerm || '',
      orFilter,
      andFilter,
      setupLink: null,
      orderAuditLink: null,
      serviceTeams: [0],
      dispatchTeams: [],
      myFreight: false,
      deadhead: false,
      columnOrder,
      sort,
      trips: null,
      vehicles: [],
    };

    // Search Setup
    this.search = this.setupSearch();
  }

  public componentDidMount() {
    this.refreshData();

    fetchApi("/api/Track/AssetNumbers")
      .then((data: { Drivers: Array<{ ID: number, Name: string }>, Vehicles: Array<{ ID: number, Name: string }> }) => {
        this.setState({ drivers: data.Drivers, vehicles: data.Vehicles });
      });

    this.refreshInterval = setInterval(this.refreshData, 60 * 1000);
    document.addEventListener(visibility.event, this.handleVisibilityChange);
  }

  public componentWillUnmount() {
    clearInterval(this.refreshInterval);
    clearTimeout(this.backgroundTimeout);
    document.removeEventListener(visibility.event, this.handleVisibilityChange);
  }

  private handleVisibilityChange() {
    if (isVisible() && this.state.backgroundMode) {
      clearTimeout(this.backgroundTimeout);
      clearInterval(this.refreshInterval);
      this.refreshInterval = setInterval(this.refreshData, 60 * 1000);
      this.setState({ backgroundMode: false });
    } else {
      clearTimeout(this.backgroundTimeout);
      this.backgroundTimeout = setTimeout(this.enterBackgroundMode, 300 * 1000);
    }
  }

  private enterBackgroundMode() {
    if (!isVisible()) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = setInterval(this.refreshData, 300 * 1000);
      this.setState({ backgroundMode: true });
    }
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.match.params && nextProps.match.params.searchTerm && nextProps.match.params.searchTerm != this.state.filterText) {
      this.setState({ filterText: nextProps.match.params.searchTerm }, () => this.filterTextChange());
    }
  }

  render() {
    return (
      <TrackContext.Provider value={{ refresh: this.refreshData }}>
        <Title string="Track" />
        {this.state.composeLink && <ComposeEmail
          CloseDialog={() => this.setState({ composeLink: null })}
          SetupNotifications={(setupLink) => this.setState({ composeLink: null, setupLink })}
          OrderAuditLogs={(orderAuditLink) => this.setState({ composeLink: null, orderAuditLink })}
          Link={this.state.composeLink} />}
        {this.state.setupLink && <SetupNotifications
          CloseDialog={() => this.setState({ setupLink: null })}
          Link={this.state.setupLink} />}
        {this.state.orderAuditLink && <OrderAuditLogGrid
          CloseDialog={() => this.setState({ orderAuditLink: null })}
          Link={this.state.orderAuditLink} />}
        {this.state.advancedSearchPopup && <AdvancedSearch
          CloseDialog={() => this.setState({ advancedSearchPopup: false })}
          Search={this.onAdvancedSearchChange}
          Query={this.state.advancedSearchQuery}
        />}
        {this.state.data !== null && this.state.data.length === 0 && this.state.filter.filters.length === 0 && !this.state.filterText && <div>
          <h1>No Trips Found..</h1>
          <p>Check your <Link to="/Account/Settings">Service Team and Dispatch Team</Link> settings.</p>
        </div>}
        {this.state.loading && <span
          className="k-i-loading k-icon"
          style={{ fontSize: 64, position: 'absolute', left: '50%', top: '5%', zIndex: 999 }}
        />}
        <Grid
          filter={this.state.filter}
          onFilterChange={this.filterChange}
          scrollable={'none'}
          sortable={{ allowUnsort: true, mode: 'multiple' }}
          sort={this.state.sort}
          onSortChange={this.sortChange}
          data={this.state.data}
          detail={TrackDetailRow}
          onExpandChange={this.expandChange}
          expandField="expanded"
          reorderable
          onColumnReorder={this.columnReorder}
        >
          <GridToolbar>
            <Input type="text" style={{ marginRight: '5px' }} placeholder="Search&hellip;" value={this.state.filterText} onChange={(value) => {
              this.setState({ filterText: value.value }, () => {
                this.filterTextChangeDebounced();
              });
            }} />
            <Button
              togglable
              icon="zoom-in"
              svgIcon={zoomInIcon}
              selected={this.state.advancedSearchQuery !== null}
              onClick={this.toggleAdvancedSearch}
            />
            <Button
              title="Clear Filters and Sort"
              icon="filter-clear"
              svgIcon={filterClearIcon}
              onClick={this.filterClear}
            />
            <Button
              title="Refresh"
              icon="refresh"
              svgIcon={arrowRotateCwIcon}
              onClick={this.refreshData}
            />
            <ButtonGroup>
              <Button togglable
                selected={this.hasAndFilter('OrderStopStatus', 'eq', 110)}
                onClick={() => this.filterAnd('OrderStopStatus', 'eq', 110)}
              >Enroute
              </Button>
              <Button togglable
                selected={this.hasAndFilter('OrderStopStatus', 'eq', 115)}
                onClick={() => this.filterAnd('OrderStopStatus', 'eq', 115)}
              >Arrived
              </Button>
            </ButtonGroup>
            <Button
              selected={this.hasAndFilter('IsRunningLate')} togglable
              onClick={() => this.filterAnd('IsRunningLate')}
            >Delayed
            </Button>
            <Button
              selected={this.hasAndFilter('TimeCriticalMinutes', 'gt', 0)} togglable
              onClick={() => this.filterAnd('TimeCriticalMinutes', 'gt', 0)}
            >Late
            </Button>
            <Button
              selected={this.hasAndFilter('IsCritical')} togglable
              onClick={() => this.filterAnd('IsCritical')}
            >Critical
            </Button>
            <Button
              selected={this.hasAndFilter('HazMat')} togglable
              onClick={() => this.filterAnd('HazMat')}
            >Hazmat
            </Button>
            <Button
              selected={this.hasAndFilter('NeedsCheckCall')} togglable
              onClick={() => this.filterAnd('NeedsCheckCall')}
            >Check Call Due
            </Button>
            <Button
              selected={this.hasAndFilter('HasEventNotification')} togglable
              onClick={() => this.filterAnd('HasEventNotification')}
            >S-EN
            </Button>
            <Button
              onClick={this.availableFilter}
            >Available
            </Button>
            <Button
              onClick={this.committedFilter}
            >Committed
            </Button>
            <Button
              onClick={this.myCommittedFilter}
            >Mine
            </Button>
            <Button
              title="DTW"
              selected={this.hasReference3Filter('DTW')} togglable
              onClick={() => this.filterReference3('DTW')}
            >DTW
            </Button>
            <Button
              title="DTW1"
              selected={this.hasReference3Filter('DTW1')} togglable
              onClick={() => this.filterReference3('DTW1')}
            >DTW1
            </Button>
            <Button
              title="DTW2"
              selected={this.hasReference3Filter('DTW2')} togglable
              onClick={() => this.filterReference3('DTW2')}
            >DTW2
            </Button>
            <Button
              title="DTW3"
              selected={this.hasReference3Filter('DTW3')} togglable
              onClick={() => this.filterReference3('DTW3')}
            >DTW3
            </Button>
            <Button
              title="DTW4"
              selected={this.hasReference3Filter('DTW4')} togglable
              onClick={() => this.filterReference3('DTW4')}
            >DTW4
            </Button>
            <Button
              title="DTW5"
              selected={this.hasReference3Filter('DTW5')} togglable
              onClick={() => this.filterReference3('DTW5')}
            >DTW5
            </Button>
            <Button
              title="DTW6"
              selected={this.hasReference3Filter('DTW6')} togglable
              onClick={() => this.filterReference3('DTW6')}
            >DTW6
            </Button>
            <Button
              title="DTW7"
              selected={this.hasReference3Filter('DTW7')} togglable
              onClick={() => this.filterReference3('DTW7')}
            >DTW7
            </Button>
            <Button
              title="DTW8"
              selected={this.hasReference3Filter('DTW8')} togglable
              onClick={() => this.filterReference3('DTW8')}
            >DTW8
            </Button>
            <Button
              title="DTW9"
              selected={this.hasReference3Filter('DTW9')} togglable
              onClick={() => this.filterReference3('DTW9')}
            >DTW9
            </Button>
            <Button
              title="DTW10"
              selected={this.hasReference3Filter('DTW10')} togglable
              onClick={() => this.filterReference3('DTW10')}
            >DTW10
            </Button>
            <Button
              title="DTW11"
              selected={this.hasReference3Filter('DTW11')} togglable
              onClick={() => this.filterReference3('DTW11')}
            >DTW11
            </Button>
            <Button
              title="CLT"
              selected={this.hasReference3Filter('CLT')} togglable
              onClick={() => this.filterReference3('CLT')}
            >Charlotte
            </Button>
            <Button
              title="DAY"
              selected={this.hasReference3Filter('DAY')} togglable
              onClick={() => this.filterReference3('DAY')}
            >Dayton
            </Button>
            <Button
              title="FWA"
              selected={this.hasReference3Filter('FWA')} togglable
              onClick={() => this.filterReference3('FWA')}
            >Fort Wayne
            </Button>
            <Button
              title="GR"
              selected={this.hasReference3Filter('GR')} togglable
              onClick={() => this.filterReference3('GR')}
            >Grand Rapids
            </Button>
            <Button
              title="TN"
              selected={this.hasReference3Filter('TN')} togglable
              onClick={() => this.filterReference3('TN')}
            >Tennessee
            </Button>
            <Button
              title="MX"
              selected={this.hasReference3Filter('MX')} togglable
              onClick={() => this.filterReference3('MX')}
            >Mexico
            </Button>
            <Button
              title="TNMX"
              selected={this.hasReference3Filter('TNMX')} togglable
              onClick={() => this.filterReference3('TNMX')}
            >Tennessee MX
            </Button>
            <Button
              title="CLTMX"
              selected={this.hasReference3Filter('CLTMX')} togglable
              onClick={() => this.filterReference3('CLTMX')}
            >Charlotte MX
            </Button>
            <Button
              selected={this.hasServiceTeam(1)} togglable
              onClick={() => this.filterServiceTeam(1)}
            >Truckload
            </Button>
            <Button
              selected={this.hasServiceTeam(2)} togglable
              onClick={() => this.filterServiceTeam(2)}
            >Brokerage
            </Button>
            <Button
              selected={this.hasServiceTeam(3)} togglable
              onClick={() => this.filterServiceTeam(3)}
            >Dedicated
            </Button>
            <Button
              selected={this.hasServiceTeam(4)} togglable
              onClick={() => this.filterServiceTeam(4)}
            >Expedite
            </Button>
            {/*<Button
              selected={this.hasServiceTeam(5)} togglable
              onClick={() => this.filterServiceTeam(5)}
            >TL Brokerage
            </Button>*/}
            <Button
              selected={this.hasServiceTeam(6)} togglable
              onClick={() => this.filterServiceTeam(6)}
            >Specialized
            </Button>
            <Button
              selected={this.hasServiceTeam(7)} togglable
              onClick={() => this.filterServiceTeam(7)}
            >Not Committed
            </Button>
            <Button
              selected={this.hasMyFreightFilter()} togglable
              onClick={() => this.filterMyFreight()}
            >My Freight
            </Button>
            <Button
              selected={this.hasMyDeadHeadFilter()} togglable
              onClick={() => this.filterDeadHead()}
            >Repositions
            </Button>
          </GridToolbar>
          {this.state.columnOrder.map((column) => {
            switch (column) {
              case defaultColumnOrder[0]:
                return <Column key={column} field="OrderNumber" title="Reference" filter="numeric" cell={ReferenceCell} />
              case defaultColumnOrder[1]:
                return <Column key={column} field="PickupDateTime" title="Pickup" filter="date" cell={PickupCell} />
              case defaultColumnOrder[2]:
                return <Column key={column} field="DeliveryDateTime" title="Delivery" filter="date" cell={DeliveryCell} />
              case defaultColumnOrder[3]:
                return <Column key={column} field="OrderStopStatus" title="Status" filter="numeric" cell={StatusCell} />
              case defaultColumnOrder[4]:
                return <Column key={column} field="AssetName" title="Asset" filter="boolean" cell={AssetCell} />
              case defaultColumnOrder[5]:
                return <Column key={column} field="HoursOfService" title="Driver/Tracking" filterable={false} cell={HOSCell} />
              case defaultColumnOrder[6]:
                return <Column key={column} field="AuthorizationCustomerNumber" title="Customer" cell={CustomerCell} />
              case defaultColumnOrder[7]:
                return <Column key={column} field="ScheduledDateTime" title="Next Stop" filter="date" cell={NextStopCell} />
              case defaultColumnOrder[8]:
                return <Column key={column} field="ETADateTime" title="Stop ETA" filter="boolean" cell={ETACell} />
              case defaultColumnOrder[9]:
                return <Column key={column} field="PositionDateTime" title="Position" filter="date" cell={PositionCell} />
              case defaultColumnOrder[10]:
                return <Column key={column} field="ETAMiles" title="Distance" filterable={false} cell={SpeedCell} />
              case defaultColumnOrder[11]:
                return <Column key={column} title="Share" filterable={false} sortable={false} cell={IconCell} width={'58px'} />
              case defaultColumnOrder[12]:
                return <Column key={column} title="Action" sortable={false} cell={ActionCell} />
              default:
                throw new Error('Unknown column: ' + column);
            }
          })}
        </Grid>
        {this.state.data && this.state.trips &&
          <div className="k-grid-toolbar k-pager-info k-label" style={{ textAlign: 'center', borderColor: 'rgba(33,37,41,.125)', borderWidth: '0 1px 1px' }}>{this.state.data.length} Record(s) Found{this.state.advancedSearchQuery && this.state.trips.length === 1000 ? '. Not All Records Displayed.' : ''}</div>}
      </TrackContext.Provider>
    );
  }

  private async refreshData() {

    // Check if we are searching for something specifically
    if (await this.filterTextChange(true)) {
      return;
    }

    const expandedRows = this.state.data ? this.state.data.filter(x => x.expanded).map(x => x.OrderID) : [];

    if (this.state.advancedSearchQuery) {
      const data = {
        OrderStatuses: this.state.advancedSearchQuery.OrderStatuses,
        DispatchStatuses: this.state.advancedSearchQuery.DispatchStatuses,
        SearchDateType: this.state.advancedSearchQuery.SearchDateType,
        FromDate: this.state.advancedSearchQuery.FromDate,
        ToDate: Moment(this.state.advancedSearchQuery.ToDate).add(1, "days"),
        IgnoreServiceTeams: this.state.advancedSearchQuery.IgnoreServiceTeams,
        SearchOptionType: this.state.advancedSearchQuery.SearchOptionType,
        SearchOptionValue: this.state.advancedSearchQuery.SearchOptionValue,
        FromState: this.state.advancedSearchQuery.FromState,
        ToState: this.state.advancedSearchQuery.ToState,
        AssetID: this.state.advancedSearchQuery.AssetID,
        AssetType: this.state.advancedSearchQuery.AssetType,
        VehicleTypeID: this.state.advancedSearchQuery.VehicleTypeID,
        ServiceTeams: this.state.advancedSearchQuery.ServiceTeams,
        DispatchTeams: this.state.dispatchTeams,
        MyFreight: this.state.advancedSearchQuery.MyFreight,
        BookUserIDs: this.state.advancedSearchQuery.BookUserIDs,
        CustomerID: this.state.advancedSearchQuery.CustomerID,
        CustomerNumber: this.state.advancedSearchQuery.CustomerNumber,
        CustomerType: this.state.advancedSearchQuery.CustomerType,
        CopiedOrderNumber: this.state.advancedSearchQuery.CopiedOrderNumber,
      }
      this.setState({ loading: true });
      fetchApi('/api/Track/GridSearch', data, 'POST')
        .then((response: { Trips: Trip[] }) => {
          this.search = this.setupSearch();
          this.search.addDocuments(response.Trips);
          response.Trips.filter(x => expandedRows.includes(x.OrderID)).forEach((trip) => {
            trip.expanded = true
          });
          this.setState({
            loading: false,
            trips: response.Trips,
          }, () => {
            this.setState({
              data: this.FilterTrips(this.state.filter, this.state.filterText)
            });
          });
        })
        .catch((exception: Error) => {
          this.setState({ loading: false });
          console.error(exception);
        });
    } else {
      if (this.state.serviceTeams.length > 0 || this.state.dispatchTeams.length > 0 || this.state.myFreight || this.state.deadhead) {
        this.setState({ loading: true });
        fetchApi('/api/Track/Grid', {
          ServiceTeams: this.state.serviceTeams.filter(x => x > 0),
          DispatchTeams: this.state.dispatchTeams,
          MyFreight: this.state.myFreight,
          DeadHead: this.state.deadhead,
        }, 'POST')
          .then((response: { Trips: Trip[], ServiceTeams: number[], DispatchTeams: string[] }) => {
            response.Trips.filter(x => expandedRows.includes(x.OrderID)).forEach((trip) => {
              trip.expanded = true
            });
            this.search = this.setupSearch();
            this.search.addDocuments(response.Trips);
            this.setState({
              loading: false,
              serviceTeams: response.ServiceTeams,
              dispatchTeams: response.DispatchTeams,
              trips: response.Trips,
            }, () => {
              this.setState({
                data: this.FilterTrips(this.state.filter, this.state.filterText)
              });
            });
          })
          .catch((exception: Error) => {
            this.setState({ loading: false });
            console.error(exception);
          });
      }
    }
  }

  private sortChange(event: { sort: SortDescriptor[] }) {
    this.setState({
      data: this.SortTrips(event.sort),
      sort: event.sort
    });

    sessionStorage.setItem("Track-sort", JSON.stringify(event.sort));
  }

  private expandChange(e: GridExpandChangeEvent) {
    e.dataItem.expanded = !e.dataItem.expanded;
    this.setState({ data: [...this.state.data] });
  }

  private columnReorder(e: GridColumnReorderEvent) {
    const columnOrder = e.columns
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map(x => x.field || x.title)
    localStorage.setItem("Track-columnOrder", JSON.stringify(columnOrder));
  }

  private availableFilter() {
    this.onAdvancedSearchChange({
      OrderStatuses: [100],
      DispatchStatuses: [],
      IgnoreServiceTeams: true,
      SearchOptionType: 1,
      SearchOptionValue: '',
      FromState: '',
      ToState: '',
      AssetID: 0,
      AssetType: 0,
      VehicleTypeID: 0,
      ServiceTeams: [],
    });
  }

  private committedFilter() {
    this.sortChange({ sort: [{ field: "PickupDateTime", dir: "asc" }] });
    this.onAdvancedSearchChange({
      OrderStatuses: [100],
      DispatchStatuses: [100],
      IgnoreServiceTeams: false,
      SearchOptionType: 1,
      SearchOptionValue: '',
      FromState: '',
      ToState: '',
      AssetID: 0,
      AssetType: 0,
      VehicleTypeID: 0,
      ServiceTeams: [1, 2, 3, 4, 5, 6],
    });
  }

  private myCommittedFilter() {
    this.sortChange({ sort: [{ field: "PickupDateTime", dir: "asc" }] });
    this.onAdvancedSearchChange({
      OrderStatuses: [100],
      DispatchStatuses: [100],
      IgnoreServiceTeams: false,
      SearchOptionType: 1,
      SearchOptionValue: '',
      FromState: '',
      ToState: '',
      AssetID: 0,
      AssetType: 0,
      VehicleTypeID: 0,
      ServiceTeams: [1, 2, 3, 4, 5, 6],
      MyFreight: true
    });
  }

  private filterChange(event: GridFilterChangeEvent) {
    this.setState({
      data: this.FilterTrips(event.filter, this.state.filterText),
      filter: event.filter
    });
  }

  private filterTextChange(refresh: boolean = false): Promise<boolean> {

    // Order Number Search
    const searchNumber = parseInt(this.state.filterText) || 0;
    const expandedRows = this.state.data ? this.state.data.filter(x => x.expanded).map(x => x.OrderID) : [];
    if (searchNumber > 1000000 && searchNumber < 9999999) {
      return fetchApi(`/api/Track/SearchOrderNumber/${searchNumber}`)
        .then((response: { Trips: Trip[], Links: ILink[] }) => {
          if (response.Trips.length > 0) {
            response.Trips.filter(x => expandedRows.includes(x.OrderID)).forEach((trip) => {
              trip.expanded = true
            });
            this.setState({ loading: false, data: response.Trips });
            return true;
          } else {
            var link = response.Links.find((x) => x.Name === 'StatusUpdateEmail');
            if (link && !refresh) {
              this.setState({ composeLink: link });
            }
          }
          return false;
        });
    }

    // Found on the screen
    const data = this.FilterTrips(this.state.filter, this.state.filterText);
    if (data.length > 0) {
      this.setState({ loading: false, data });
      return Promise.resolve(false);
    }

    // Driver Number Search
    var driverResult = this.state.drivers.find(x => x.Name === this.state.filterText.toUpperCase());
    if (driverResult) {
      return fetchApi(`/api/Track/SearchDriverID/${driverResult.ID}`)
        .then((response: { Trips: Trip[] }) => {
          response.Trips.filter(x => expandedRows.includes(x.OrderID)).forEach((trip) => {
            trip.expanded = true
          });
          this.setState({ loading: response.Trips.length === 0, data: response.Trips });
          return response.Trips.length > 0;
        });
    }

    // Vehicle Number Search
    var vehicleResult = this.state.vehicles.find(x => x.Name === this.state.filterText.toUpperCase());
    if (vehicleResult) {
      return fetchApi(`/api/Track/SearchVehicleID/${vehicleResult.ID}`)
        .then((response: { Trips: Trip[] }) => {
          response.Trips.filter(x => expandedRows.includes(x.OrderID)).forEach((trip) => {
            trip.expanded = true
          });
          this.setState({ loading: response.Trips.length === 0, data: response.Trips });
          return response.Trips.length > 0;
        });
    }

    this.setState({ data: [] });
    return Promise.resolve(false);
  }
  private filterTextChangeDebounced = debounce(this.filterTextChange, 250);

  private SortTrips(sort: SortDescriptor[]): Trip[] {
    return orderBy(this.state.data as Trip[], sort);
  }

  private FilterTrips(filter: CompositeFilterDescriptor, filterText: string): Trip[] {

    // Text Filter
    let filteredTrips: Trip[] = [];

    if (filterText) {
      if (/^[0-9,\s]+$/.test(filterText) && filterText.length > 7 && filterText.indexOf(',') > -1) {
        filterText.split(',').forEach((term) => {
          if (term.length > 6) {
            const filteredTripsAdd = this.search.search(term) as Trip[];
            filteredTripsAdd.forEach((filteredTrip) => {
              if (!filteredTrips.find(x => x.TripNumber == filteredTrip.TripNumber)) {
                filteredTrips.push(filteredTrip);
              }
            });
          }
        });
      } else {
        filteredTrips.push(...this.search.search(filterText) as Trip[]);
      }
    } else {
      filteredTrips = this.state.trips ? this.state.trips.slice() : []
    }

    // Kendo Filter
    filteredTrips = filterBy(orderBy(filteredTrips, this.state.sort), filter);

    return filteredTrips;
  }

  private hasAndFilter(field: string, operator: string = 'eq', value: any = true): boolean {
    return this.state.andFilter
      .filter(x => x.field === field && x.operator === operator && x.value === value)
      .length > 0;
  }

  private filterAnd(field: string, operator: string = 'eq', value: any = true) {
    const enabled = this.hasAndFilter(field, operator, value);
    const existingFilter = this.state.andFilter.filter(x => x.field !== field);
    let newStatusFilter = [...existingFilter, { field: field, operator: operator, value: value }] as FilterDescriptor[];
    if (enabled) {
      newStatusFilter = existingFilter;
    } else if (field === 'NeedsCheckCall') {
      this.sortChange({ sort: [{ field: "PositionDateTime", dir: "asc" }] });
    }
    const newFilter = {
      logic: 'and',
      filters: [
        {
          logic: 'or',
          filters: this.state.orFilter
        } as CompositeFilterDescriptor,
        {
          logic: 'and',
          filters: newStatusFilter
        } as CompositeFilterDescriptor
      ].filter((x) => x.filters.length)
    } as CompositeFilterDescriptor;
    this.setState({
      data: this.FilterTrips(newFilter, this.state.filterText),
      filter: newFilter,
      andFilter: newStatusFilter,
    });

    sessionStorage.setItem("Track-andFilter", JSON.stringify(newStatusFilter));
  }

  private filterClear() {
    const newFilter = {
      logic: 'and',
      filters: []
    } as CompositeFilterDescriptor;
    const hadAdvanecedFilter = this.state.advancedSearchQuery != null;
    this.setState({
      advancedSearchQuery: null,
      sort: [],
      data: this.FilterTrips(newFilter, ''),
      filter: newFilter,
      filterText: '',
      andFilter: [],
      orFilter: [],
    }, () => {
      if (hadAdvanecedFilter) {
        this.refreshData();
      }
    });

    sessionStorage.setItem("Track-andFilter", JSON.stringify([]));
    sessionStorage.setItem("Track-orFilter", JSON.stringify([]));
  }

  private hasServiceTeam(serviceTeam: number): boolean {
    if (this.state.advancedSearchQuery) {
      return this.state.advancedSearchQuery.ServiceTeams.indexOf(serviceTeam) > -1 && !this.state.advancedSearchQuery.IgnoreServiceTeams;
    }
    return this.state.serviceTeams.indexOf(serviceTeam) > -1;
  }

  private filterServiceTeam(serviceTeam: number): void {
    if (this.state.advancedSearchQuery) {
      this.onAdvancedSearchChange({
        ServiceTeams: this.state.advancedSearchQuery.ServiceTeams.indexOf(serviceTeam) > -1 ?
          this.state.advancedSearchQuery.ServiceTeams.filter(x => x !== serviceTeam) : [...this.state.advancedSearchQuery.ServiceTeams, serviceTeam],
        IgnoreServiceTeams: false,
      });
      return;
    }
    this.setState(prevState => ({
      serviceTeams: prevState.serviceTeams.indexOf(serviceTeam) > -1 ?
        prevState.serviceTeams.filter(x => x !== serviceTeam) : [...prevState.serviceTeams, serviceTeam]
    }), () => this.refreshData());
  }

  private hasReference3Filter(ref: string): boolean {
    return this.state.dispatchTeams.indexOf(ref) > -1;
  }

  private filterReference3(ref: string) {
    this.setState(prevState => ({
      dispatchTeams: prevState.dispatchTeams.indexOf(ref) > -1 ?
        prevState.dispatchTeams.filter(x => x !== ref) : [...prevState.dispatchTeams, ref],
    }), () => this.refreshData());
  }

  private hasMyFreightFilter(): boolean {
    return this.state.myFreight;
  }

  private filterMyFreight() {
    this.setState(prevState => ({
      dispatchTeams: !prevState.myFreight ? [] : prevState.dispatchTeams,
      serviceTeams: !prevState.myFreight || (prevState.serviceTeams.length === 0 && prevState.dispatchTeams.length === 0) ? [0] : prevState.serviceTeams,
      myFreight: !prevState.myFreight
    }), async () => await this.refreshData());
  }

  private hasMyDeadHeadFilter(): boolean {
    return this.state.deadhead && this.state.advancedSearchQuery === null;
  }

  private filterDeadHead() {
    this.setState(prevState => ({
      dispatchTeams: !prevState.deadhead ? [] : prevState.dispatchTeams,
      serviceTeams: !prevState.deadhead || (prevState.serviceTeams.length === 0 && prevState.dispatchTeams.length === 0) ? [0] : prevState.serviceTeams,
      deadhead: !prevState.deadhead
    }), async () => await this.refreshData());
  }

  private toggleAdvancedSearch() {
    this.setState({ advancedSearchPopup: true });
  }

  private onAdvancedSearchChange(newAdvancedSearchQuery: null | Partial<AdvancedSearchQuery>) {
    if (newAdvancedSearchQuery === null) {
      this.setState({ advancedSearchQuery: null }, () => this.refreshData());
      return;
    }
    const defaultAdvancedSearchQuery = this.state.advancedSearchQuery || {
      FromDate: Moment().add(-7, "days").startOf('day').toDate(),
      ToDate: Moment().add(7, "days").toDate(),
    } as AdvancedSearchQuery;
    const advancedSearchQuery = Object.assign({}, defaultAdvancedSearchQuery, newAdvancedSearchQuery);
    this.setState({ advancedSearchQuery }, () => this.refreshData());
  }

  private setupSearch(): JsSearch.Search {
    const search = new JsSearch.Search('Hash')
    search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    search.addIndex("TripNumber");
    search.addIndex("OrderNumber");
    search.addIndex("ReferenceNumber1");
    search.addIndex("ReferenceNumber2");
    search.addIndex("StatusNote");
    search.addIndex("PickupLocation");
    search.addIndex("DeliveryLocation");
    search.addIndex("AssetName");
    search.addIndex(["Drivers", "0", "DriverNumber"]);
    search.addIndex(["Drivers", "1", "DriverNumber"]);
    search.addIndex("VehicleTypeName");
    search.addIndex("AuthorizationCustomerNumber")
    return search;
  }
}

export default Track;
