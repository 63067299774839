import { IControl, Point } from "@trimblemaps/trimblemaps-js";
import * as DOM from '../../utils/dom';
import { fetchApi } from "services/api";
import { Feature, Geometry } from "@trimblemaps/trimblemaps-js/geojson";

export type BookedControlOptions = {
  vehicleTypeId?: number;
}

class TrimbleMapBookedControl implements IControl {
  _map: TrimbleMaps.Map;
  _container: HTMLElement;
  _button: HTMLElement;
  _showing: boolean;
  options: BookedControlOptions;

  constructor(options: BookedControlOptions = {}) {
    this.options = options;

    this._onClick = this._onClick.bind(this);
  }

  onAdd(map: TrimbleMaps.Map): HTMLElement {
    this._map = map;
    this._container = DOM.create('div', 'trimblemaps-ctrl trimblemaps-ctrl-group', map.getContainer());

    const button = this._button = DOM.create('button', 'trimblemaps-ctrl-icon trimblemaps-ctrl-loadmap', this._container);
    DOM.create('span', `trimblemaps-ctrl-icon`, button).setAttribute('aria-hidden', 'true');
    // @ts-expect-error - TS2339 - Property 'type' does not exist on type 'HTMLElement'.
    button.type = 'button';
    const title = "Booked Last 7 Days";
    this._button.setAttribute("aria-label", title);
    if (this._button.firstElementChild) this._button.firstElementChild.setAttribute('title', title);
    this._button.addEventListener('click', this._onClick);

    // Remove any existing layers
    this._cleanUp();

    return this._container;
  }

  onRemove() {
    if (!this._container) return;

    this._cleanUp();
    this._container.remove();
    this._map = (undefined as any);
  }

  _cleanUp() {
    this._map.getLayer('unclusteredPoints') && this._map.removeLayer('unclusteredPoints');
    this._map.getLayer('clusterCount') && this._map.removeLayer('clusterCount');
    this._map.getLayer('clusteredPoints') && this._map.removeLayer('clusteredPoints');
    this._map.getSource('bookedPoints') && this._map.removeSource('bookedPoints');

    this._showing = false;
    this._button.classList.remove('trimblemaps-ctrl-loadmap-visible');
  }

  _onClick = () => {

    // Skip if no vehicle type
    if (!this.options.vehicleTypeId) return;

    // Toggle visibility
    if (this._showing) {
      this._cleanUp();
      return;
    }

    this._showing = true;
    this._button.classList.add('trimblemaps-ctrl-loadmap-visible');
    fetchApi('/api/Quote/RecentBooked', { VehicleTypeID: this.options.vehicleTypeId }, 'POST')
    .then((data: Array<{ Latitude: number, Longitude: number, Label: string }>) => {
      const features = data.map((d) => ({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [d.Longitude, d.Latitude]
        }
      })) as Array<Feature<Geometry, Point>>;

      // Use cluster properties on datasource to group data
      this._map.addSource('bookedPoints', {
        type: 'geojson',
        cluster: true,
        clusterRadius: 40,
        clusterMaxZoom: 14,
        data: {
          type: 'FeatureCollection',
          features: features
        }
      });

      this._map.addLayer({
        id: 'clusteredPoints',
        type: 'circle',
        source: 'bookedPoints',
        filter: ['has', 'point_count'],
        paint: {
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            15, 5,
            20, 50,
            25
          ],
          'circle-color': '#088000',
          'circle-stroke-color': '#FFF',
          'circle-stroke-width': 3
        }
      });

      // Show count for clustered points
      this._map.addLayer({
        id: 'clusterCount',
        type: 'symbol',
        source: 'bookedPoints',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count}',
          'text-font': ['Roboto Regular'],
          'text-size': 12
        },
        paint: {
          'text-color': '#FFF'
        }
      });

      // Use filter to show unclustered points
      this._map.addLayer({
        id: 'unclusteredPoints',
        type: 'circle',
        source: 'bookedPoints',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-radius': 10,
          'circle-color': '#088000',
          'circle-stroke-color': '#FFF',
          'circle-stroke-width': 2
        }
      });
    });
  }
}

export default TrimbleMapBookedControl;