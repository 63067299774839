import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid, GridCellProps, GridColumn as Column, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, NumericTextBox, NumericTextBoxChangeEvent, TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import SimpleDatePicker from '../../components/SimpleDatePicker';
import useAlert from '../../components/useAlert';
import { fetchApi } from '../../services/api';
import { isNullOrWhiteSpace } from '../../utils/utils';
import { formatNumber } from '@progress/kendo-intl';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { IDName } from 'types/idname';
import PayRateAccessorialDetail from './PayRateAccessorialDetail';

type Props = {
  CloseDialog?: () => void;
};

export type PayRateSummary = {
  PayRates: PayRateViewModel[];
};

export type PayRateViewModel = {
  PayRateAccessorialID: number;

  ModifiedByFullName: string;
  ModifiedDateTime: Date;
  StartDate: Date;
  EndDate: Date;
  Name: string;

  inEdit?: boolean | string;
  isNew?: boolean;
  IsExpired: boolean;
};

const LastModifiedByCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: PayRateViewModel = props.dataItem;
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} - {dataItem.ModifiedByFullName}</span>
    </td>
  );
};

const textBoxCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  const handleChange = (e: TextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  var value = props.dataItem[props.field] as string;
  let dataItem: PayRateViewModel = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.inEdit ?
        <TextBox
          value={value}
          onChange={handleChange}
          style={{ width: '100%' }}
        /> :
        <span>{value}</span>
      }
    </td>
  );
};

interface EditCommandCellProps extends GridCellProps {
  edit: (item: PayRateViewModel) => void;
  //remove: (item: PayRateViewModel) => void;
  add: (item: PayRateViewModel) => void;
  discard: () => void;
  update: (item: PayRateViewModel) => void;
  cancel: (item: PayRateViewModel) => void;
  editField: string;
}

const DateCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  const handleChange = (e: Date) => {
    if (props.field === "StartDate") {
      e = Moment.utc(e).tz("America/New_York").toDate();
    } else {
      e = Moment.utc(e).tz("America/New_York").endOf('day').toDate();
    }

    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: null,
        value: e,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {props.dataItem.inEdit ?
        <SimpleDatePicker
          value={Moment.utc(props.dataItem[props.field]).tz("America/New_York").toDate()}
          onChange={handleChange}
        />
        :
        <div className={props.field === 'EndDate' && props.dataItem.IsExpired ? 'text-warning' : ''}>
          {Moment.utc(props.dataItem[props.field]).tz("America/New_York").format("MM/DD/YYYY")}
        </div>
      }
    </td>
  );
};

const PayRateAccessorial = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const editField: string = "inEdit";
  const [CSIDataCopy, setCSIDataCopy] = useState<PayRateViewModel[]>([]);
  const [PayRates, setPayRates] = useState<PayRateViewModel[]>([]);
  const [hideExpiredPayRateStructures, setHideExpiredPayRateStructures] = useState(true);
  const { alert } = useAlert();
  const [selectedPayRateAccessorial, setSelectedPayRateAccessorial] = useState<PayRateViewModel>();

  const PayRateAccessorialIDCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: PayRateViewModel = props.dataItem;

    return dataItem.inEdit ? (
      <td colSpan={props.colSpan} style={props.style}>
        <span className="ml-2">{dataItem.isNew ? '-' : dataItem.PayRateAccessorialID}</span>
      </td>
    ) : (
      <td colSpan={props.colSpan} style={props.style}>
        <Button fillMode="flat" size="small" themeColor="primary" onClick={() => setSelectedPayRateAccessorial(dataItem)}>{dataItem.PayRateAccessorialID}</Button>
      </td>
    );
  };

  const EditCommandCell = (props: EditCommandCellProps) => {
    const inEdit = props.dataItem[props.editField];
    let dataItem: PayRateViewModel = props.dataItem;
    const isNewItem = dataItem.isNew;

    const disabled = dataItem.StartDate == null || dataItem.EndDate == null || isNullOrWhiteSpace(dataItem.Name)

    return inEdit ? (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={disabled}
          onClick={() =>
            isNewItem ? props.add(dataItem) : props.update(dataItem)
          }
        >
          {isNewItem ? "Save" : "Update"}
        </Button>
        <Button themeColor={"secondary"}
          onClick={() =>
            isNewItem ? props.discard() : props.cancel(dataItem)
          }
        >
          {isNewItem ? "Discard" : "Cancel"}
        </Button>
      </td>
    ) : (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={PayRates.some(x => x.inEdit === true)}
          onClick={() => props.edit(dataItem)}
        >
          Edit
        </Button>
      </td>
    );
  };

  const refresh = useCallback(() => {
    setLoading(true);

    fetchApi('/api/PayRate/Accessorial')
      .then((summary: PayRateSummary) => {
        setLoading(false);

        summary.PayRates.forEach(obj => {
          obj.StartDate = Moment.utc(obj.StartDate).tz("America/New_York").toDate();
          obj.EndDate = Moment.utc(obj.EndDate).tz("America/New_York").toDate();
        });

        setPayRates(summary.PayRates);
        setCSIDataCopy(summary.PayRates);
      })
      .catch(async err => {
        setLoading(false);
        await alert(err);
      });
  }, [alert]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const enterEdit = (dataItem: PayRateViewModel) => {
    if (PayRates.some(x => x.inEdit === true))
      return;

    setPayRates(
      PayRates.map((item) =>
        item.PayRateAccessorialID === dataItem.PayRateAccessorialID ? { ...item, inEdit: true } : item
      )
    );
  };

  const updatePayRates = (dataItem: PayRateViewModel) => {
    setLoading(true);
    const data = { ...dataItem }
    fetchApi('/api/PayRate/Accessorial', data, 'POST')
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        }
        else
          refresh();
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred while saving.');
      });
  }

  const add = (dataItem: PayRateViewModel) => {
    updatePayRates(dataItem);
  };

  const update = (dataItem: PayRateViewModel) => {
    updatePayRates(dataItem);
  };

  const discard = () => {
    const newData = [...PayRates];
    newData.splice(0, 1);
    setPayRates(newData);
  };

  const cancel = (dataItem: PayRateViewModel) => {
    const originalItem = CSIDataCopy.find(
      (p) => p.PayRateAccessorialID === dataItem.PayRateAccessorialID
    );
    const newData = PayRates.map((item) =>
      item.PayRateAccessorialID === originalItem.PayRateAccessorialID ? originalItem : item
    );

    setPayRates(newData);
  };

  const generateId = () =>
    PayRates.reduce((acc, current) => Math.max(acc, current.PayRateAccessorialID), 0) + 1;

  const addNew = () => {
    const newDataItem = {
      inEdit: true,
      PayRateAccessorialID: generateId(),
      isNew: true,
      Name: '',
      StartDate: Moment.utc().tz("America/New_York").startOf('day').toDate(),
      EndDate: Moment([2999, 11, 31]).utc().tz("America/New_York").endOf('day').toDate(),
      IsExpired: false,
    } as PayRateViewModel;
    setPayRates([newDataItem, ...PayRates]);
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const dataItem = event.dataItem as PayRateViewModel;
    const newData = PayRates.map((item) =>
      item.PayRateAccessorialID === dataItem.PayRateAccessorialID
        ? { ...item, [event.field || ""]: event.value }
        : item
    );

    setPayRates(newData);
  }

  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props}
      edit={enterEdit}
      //remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const dataView = () => {
    return (<>
      <div className="container-fluid mt-4" style={{ position: "relative" }}>
        {loading && <CenterDivPanel>
          <Loader type="converging-spinner" />
        </CenterDivPanel>}
        <h4 className="text-left">Pay Rate Accessorial Maintance</h4>
        <Grid
          scrollable="none"
          data={hideExpiredPayRateStructures ? PayRates.filter(x => x.IsExpired === false) : PayRates}
          onItemChange={itemChange}
          editField={editField}
          onRowDoubleClick={(e: GridRowDoubleClickEvent) => { enterEdit(e.dataItem); }}
        >
          <GridToolbar>
            <Button themeColor={"primary"}
              disabled={PayRates.some(x => x.inEdit === true)}
              title="Add new"
              onClick={addNew}
            >
              Add New Pay Rate
            </Button>
            <span>
              <Checkbox
                disabled={PayRates.some(x => x.inEdit === true)}
                className="ml-2"
                value={hideExpiredPayRateStructures}
                onChange={(e) => setHideExpiredPayRateStructures(e.value)}
                label={"Hide Expired Pay Rate Structures"}
              />
            </span>
          </GridToolbar>
          <Column field="PayRateAccessorialID" title="Rate Code" editable={false} cell={PayRateAccessorialIDCell} />
          <Column field="Name" title="Name" cell={textBoxCell} />
          <Column field="StartDate" title="Effective From" cell={DateCell} width={175} />
          <Column field="EndDate" title="Effective To" cell={DateCell} width={175} />
          <Column field="ModifiedByFullName" title="Modified By" cell={LastModifiedByCell} width={250} />
          <Column cell={MyEditCommandCell} width={160} />
        </Grid>
        {selectedPayRateAccessorial &&
          <Dialog title={`Pay Rate Accessorial (${selectedPayRateAccessorial.PayRateAccessorialID})`} className='dialog-w11/12' onClose={() => setSelectedPayRateAccessorial(null)}>
            <PayRateAccessorialDetail PayRateAccessorialID={selectedPayRateAccessorial.PayRateAccessorialID} />
          </Dialog>}
      </div>
    </>);
  };

  if (props.CloseDialog) {
    return <Dialog title={`Set Pay Rate Accessorial`} onClose={props.CloseDialog} className='dialog-w11/12'>
      {dataView()}
    </Dialog>
  }

  return dataView();
}

export default PayRateAccessorial;
