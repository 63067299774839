import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import CenterDivPanel from '../../components/CenterDivPanel';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';
import { IDName } from '../../types/idname';
import { ILink } from '../../types/link';
import { JsonResponse } from 'TypeGen/json-response';
import { PayByAccessorialType } from 'TypeGen/AccountsPayable/RateMaintenance/AccessorialDetails/pay-by-accessorial-type';

type Props = {
    PayRateAccessorialID: number
};

export type VehicleRateBreak = {
    RateDescriptionID: number;
    RateDescriptionName: string;
    RateLineItemID: number;
    ModifiedByFullName: string;
    ModifiedDateTime: Date;

    PayBy: number;
    PayRate: PayByAccessorialType;

    inEdit?: boolean | string;
    isNew?: boolean;
    Delete?: boolean;
};

const PayByAccessorialTypeList: IDName[] = [
    { ID: PayByAccessorialType.PerMile, Name: "Per Mile" },
    { ID: PayByAccessorialType.PerKM, Name: "Per KM" },
    { ID: PayByAccessorialType.PercOfAcc, Name: "% of Acc." },
    { ID: PayByAccessorialType.Flat, Name: "Flat" },
    { ID: PayByAccessorialType.PercOfDLP, Name: "% of DLP" },
    { ID: PayByAccessorialType.RelPercOfRev, Name: "Rel. % of Rev." },
    { ID: PayByAccessorialType.TariffFuelTable, Name: "Tariff Fuel Table" }
  ];

type RateMasterSummaryResponse = {
    Details: VehicleRateBreak[];
    RateDescriptionList: IDName[];
    Links: ILink[]
};

interface EditCommandCellProps extends GridCellProps {
    edit: (item: VehicleRateBreak) => void;
    remove: (item: VehicleRateBreak) => void;
    add: (item: VehicleRateBreak) => void;
    discard: () => void;
    update: (item: VehicleRateBreak) => void;
    cancel: (item: VehicleRateBreak) => void;
    editField: string;
}

const PayRateDetailContext = createContext<{ RateDescriptionList: IDName[] }>({ RateDescriptionList: [] });

const AccessorialCell = (props: GridCellProps) => {
    const context = useContext(PayRateDetailContext);

    let dataItem: VehicleRateBreak = props.dataItem;

    const handleChange = (e: DropDownListChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.ID,
            });
        }
    };

    return (
        <td colSpan={props.colSpan} style={props.style}>
            {dataItem.inEdit ? <DropDownList
                className="my-1"
                data={context.RateDescriptionList}
                textField="Name"
                dataItemKey="ID"
                value={context.RateDescriptionList.find((x: IDName) => x.ID === dataItem.RateDescriptionID)}
                onChange={handleChange}
            /> :
                <div>{context.RateDescriptionList.find((x: IDName) => x.ID === dataItem.RateDescriptionID).Name}</div>
            }
        </td>
    );
};

const RateCell = (props: GridCellProps) => {
    let dataItem: VehicleRateBreak = props.dataItem;

    const handleChange = (e: NumericTextBoxChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value
            });
        }
    };
    var value = props.dataItem[props.field] as number;
    var rateFormat = dataItem.PayBy === PayByAccessorialType.PercOfAcc || dataItem.PayBy === PayByAccessorialType.PercOfDLP ? "p0" : "c2";

    return (
        <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
            {dataItem.inEdit ? <NumericTextBox format={rateFormat} step={0.01} value={value} onChange={handleChange} min={0} inputStyle={{ textAlign: "right" }} /> :
                <span>{formatNumber(value, rateFormat)}</span>}
        </td>
    );
};

const PayByCell = (props: GridCellProps) => {

    let dataItem: VehicleRateBreak = props.dataItem;
  
    const handleChange = (e: DropDownListChangeEvent) => {
      if (props.onChange) {
  
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: props.field,
          syntheticEvent: e.syntheticEvent,
          value: e.target.value.ID,
        });
      }
    };
  
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {dataItem.inEdit ? <DropDownList
          className="my-1"
          data={PayByAccessorialTypeList}
          textField="Name"
          dataItemKey="ID"
          value={PayByAccessorialTypeList.find((x: IDName) => x.ID == dataItem.PayBy)}
          onChange={handleChange}
        /> :
          <div>{PayByAccessorialTypeList.find((x: IDName) => x.ID == dataItem.PayBy)?.Name}</div>
        }
      </td>
    );
  };

const LastModifiedByCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: VehicleRateBreak = props.dataItem;
    return (
      <td colSpan={props.colSpan} style={props.style}>
        <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} - {dataItem.ModifiedByFullName}</span>
      </td>
    );
};

const PayRateAccessorialDetail = (props: Props) => {
    const { alert } = useAlert();
    const { ConfirmationDialog, confirm } = useConfirm({});
    const [loading, setLoading] = useState(true);
    const editField: string = "inEdit";
    const [rateDescriptionList, setRateDescriptionList] = useState<IDName[]>([]);
    const [vehicleRateBreaks, setVehicleRateBreaks] = useState<VehicleRateBreak[]>([]);
    const [CSIDataCopy, setCSIDataCopy] = useState<VehicleRateBreak[]>([]);
    const [rateMasterSummaryResponse, setRateMasterSummaryResponse] = useState<RateMasterSummaryResponse>();

    const EditCommandCell = (props: EditCommandCellProps) => {
        const inEdit = props.dataItem[props.editField];
        let dataItem: VehicleRateBreak = props.dataItem;
        const isNewItem = dataItem.isNew;

        let disabled = dataItem.PayRate == null;

        return inEdit ? (
            <td className="k-command-cell">
                <Button themeColor={"primary"}
                    disabled={disabled}
                    onClick={() =>
                        isNewItem ? props.add(dataItem) : props.update(dataItem)
                    }
                >
                    {isNewItem ? "Save" : "Update"}
                </Button>
                <Button themeColor={"secondary"}
                    onClick={() =>
                        isNewItem ? props.discard() : props.cancel(dataItem)
                    }
                >
                    {isNewItem ? "Discard" : "Cancel"}
                </Button>
            </td>
        ) : (
            <td className="k-command-cell">
                <Button themeColor={"primary"} disabled={vehicleRateBreaks.some(x => x.inEdit === true)}
                    onClick={() => props.edit(dataItem)}>Edit</Button>
                <Button themeColor={"secondary"} disabled={vehicleRateBreaks.some(x => x.inEdit === true)}
                    onClick={async () => {
                        if (!await confirm(`Remove ${rateDescriptionList.find(x => x.ID === dataItem.RateDescriptionID).Name}?`))
                            return;
                        props.remove(dataItem);
                    }}>Delete</Button>
            </td>
        );
    };

    const MyEditCommandCell = (props: GridCellProps) => (
        <EditCommandCell {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    const enterEdit = (dataItem: VehicleRateBreak) => {
        if (vehicleRateBreaks.some(x => x.inEdit === true))
            return;

        setVehicleRateBreaks(
            vehicleRateBreaks.map((item) =>
                item.RateLineItemID === dataItem.RateLineItemID ? { ...item, inEdit: true } : item
            )
        );
    };

    const remove = (dataItem: VehicleRateBreak) => {
        updateAccessorialRateStructure({ ...dataItem, Delete: true });
    };

    const add = (dataItem: VehicleRateBreak) => {
        updateAccessorialRateStructure(dataItem);
    };

    const update = (dataItem: VehicleRateBreak) => {
        updateAccessorialRateStructure(dataItem);
    };

    const cancel = (dataItem: VehicleRateBreak) => {
        const originalItem = CSIDataCopy.find(
            (p) => p.RateLineItemID === dataItem.RateLineItemID
        );
        const newData = vehicleRateBreaks.map((item) =>
            item.RateLineItemID === originalItem.RateLineItemID ? cloneObject(originalItem) : item
        );

        setVehicleRateBreaks(newData);
    };

    const generateId = () =>
        vehicleRateBreaks.reduce((acc, current) => Math.max(acc, current.RateLineItemID), 0) + 1;

    const addNew = () => {
        const newDataItem = {
            inEdit: true, RateLineItemID: generateId(), isNew: true,
        } as VehicleRateBreak;
        setVehicleRateBreaks([newDataItem, ...vehicleRateBreaks]);
    };

    const discard = () => {
        const newData = [...vehicleRateBreaks];
        newData.splice(0, 1);
        setVehicleRateBreaks(newData);
    };

    const getRateMaster = useCallback(() => {
        setLoading(true);

        fetchApi(`/api/PayRate/AccessorialDetails/${props.PayRateAccessorialID}`)
            .then((response: RateMasterSummaryResponse) => {
                setRateMasterSummaryResponse(response);
            })
            .catch(async e => {
                if (!e?.status)
                    await alert('Error: Please see admin');
                else if (e.status !== 404) {
                    await alert(e?.detail);
                }
                setLoading(false);
            });
    }, [props.PayRateAccessorialID, alert]);


    const cloneObject = (obj: any): any => {
        return JSON.parse(JSON.stringify(obj));
    }

    const refresh = useCallback(() => {
        setLoading(true);

        fetchApi(`/api/PayRate/AccessorialDetails/${props.PayRateAccessorialID}`)
            .then((response: RateMasterSummaryResponse) => {
                setRateDescriptionList(response.RateDescriptionList);
                setVehicleRateBreaks(response.Details);
                setCSIDataCopy(cloneObject(response.Details)) //deep copy/clone

                setLoading(false);
            })
            .catch(async e => {
                if (!e?.status)
                    await alert('Error: Please see admin');
                else if (e.status !== 404) {
                    await alert(e?.detail);
                }
                setLoading(false);
            });
    }, [props.PayRateAccessorialID, alert]);

    const updateAccessorialRateStructure = (dataItem: VehicleRateBreak) => {
        setLoading(true);

        const data = { ...dataItem }
        fetchApi(`/api/PayRate/AccessorialDetails/${props.PayRateAccessorialID}`, data, 'POST')
            .then(async (response: JsonResponse) => {
                if (response.Success === false) {
                    setLoading(false);
                    await alert(`Error: ${response.ErrorMessage}`);
                }
                else
                    refresh();
            })
            .catch(async e => {
                setLoading(false);
                // If not problem details
                if (!e?.status) await alert('An error occurred while saving.');
            });
    }

    useEffect(() => {
        getRateMaster();
    }, [props.PayRateAccessorialID, getRateMaster]);

    useEffect(() => {
        if (rateMasterSummaryResponse) {
            refresh();
        }
    }, [rateMasterSummaryResponse, refresh]);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || "";
        const newData = vehicleRateBreaks.map((item) =>
            item.RateLineItemID === event.dataItem.RateLineItemID
                ? { ...item, [field]: event.value }
                : item
        );

        setVehicleRateBreaks(newData);
    }

    const dataView = () => {
        return (<div className="container-fluid mt-2" style={{ position: "relative" }}>
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <PayRateDetailContext.Provider value={{ RateDescriptionList: rateDescriptionList ?? [] }}>
                <Grid
                    scrollable='none'
                    onRowDoubleClick={(e: GridRowDoubleClickEvent) => enterEdit(e.dataItem)}
                    data={vehicleRateBreaks}
                    onItemChange={itemChange}
                >
                    <GridToolbar>
                        <Button themeColor={"primary"}
                            disabled={vehicleRateBreaks.some(x => x.inEdit === true)}
                            title="Add new"
                            onClick={addNew}
                        >
                            Add new
                        </Button>
                    </GridToolbar>
                    <Column field="RateDescriptionID" title="Charge Type" cell={AccessorialCell} />
                    <Column field="PayRate" title="Rate" cell={RateCell} />
                    <Column field="PayBy" title="Pay By" cell={PayByCell} />
                    <Column field="ModifiedByFullName" title="Modified By" cell={LastModifiedByCell} width={250} />
                    <Column cell={MyEditCommandCell} width={160} />
                </Grid>
            </PayRateDetailContext.Provider>
            <ConfirmationDialog />
        </div>);
    };

    return dataView();
}


export default PayRateAccessorialDetail;
