import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { DateInput, DateInputProps } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridEvent, GridHeaderCellProps, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { filterClearIcon } from '@progress/kendo-svg-icons';
import Moment from 'moment-timezone';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'ts-debounce';
import { ViewModel as PayListingRecord } from 'TypeGen/AccountsPayable/PayListing/view-model';
import { Title } from 'utils/title';
import useMessage from 'utils/useMessage';
import CenterDivPanel from '../../components/CenterDivPanel';
import SimpleDateRangePicker from '../../components/SimpleDateRangePicker';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';
import { openWindowPerc } from '../../services/openWindow';
import { PayStatus } from '../../TypeGen/Pay/pay-status';
import { IDName } from '../../types/idname';
import CarrierAutoComplete from '../AssetCarriers/CarrierAutoComplete';
import DriverAutoComplete from '../AssetDrivers/DriverAutoComplete';
import OwnerAutoComplete from '../AssetOwners/OwnerAutoComplete';
import VehicleAutoComplete from '../AssetVehicles/VehicleAutoComplete';
import PayPeriodDropDownList from './PayPeriodDropDownList';

const PayListingContext = createContext<{
  TransmitEpayRecord: (payRecord: PayListingRecord) => void;
}>({
  TransmitEpayRecord: null
})

export const usePayListing = () => useContext(PayListingContext);

enum EpayStatusTypes {
  Any = -1,
  NotTransmitted = 0,
  ReadyToTransmit = 100,
  Transmitting = 120,
  Disputed = 121,
  Denied = 122,
  Invoiced = 123,
  Approved = 124,
  Error = 125,
  Processing = 127,
  Cancelled = 128,
  Completed = 130
}

export const EpayStatuses = [
  { ID: -1, Name: 'Any Epay Status' },
  { ID: EpayStatusTypes.NotTransmitted, Name: 'Not Transmitted' },
  { ID: EpayStatusTypes.ReadyToTransmit, Name: 'Ready To Transmit' },
  { ID: EpayStatusTypes.Transmitting, Name: 'Transmitting' },
  { ID: EpayStatusTypes.Disputed, Name: 'Disputed' },
  { ID: EpayStatusTypes.Denied, Name: 'Rejected' },
  { ID: EpayStatusTypes.Invoiced, Name: 'Invoiced' },
  { ID: EpayStatusTypes.Approved, Name: 'Approved' },
  //{ ID: EpayStatusTypes.Error, Name: 'Error' },
  { ID: EpayStatusTypes.Processing, Name: 'Processing' },
  { ID: EpayStatusTypes.Cancelled, Name: 'Cancelled' },
  { ID: EpayStatusTypes.Completed, Name: 'Completed' }
] as IDName[];

type PostDataState = {
  skip: number,
  take: number,
  sort: string,
  filters: PostDataFilter,
};

type PostDataFilter = {
  PickupFromDateTime: Date,
  PickupToDateTime: Date,
  PayStatus: number,
  AssetType: string
  OrderNumber: number | null,
  TripNumber?: number | null,
  DriverID?: number | null,
  VehicleID?: number | null,
  OwnerID?: number | null,
  CarrierID?: number | null,
  PayPeriodID?: number | null,
  EpayStatus?: number | null
};

const AssetCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: PayListingRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.VehicleID > 0 ?
        <>
          <div><Link style={{ color: "#007bff" }} to="#" onClick={(e) => {
            e.preventDefault();
            openWindowPerc(`/Assets/Driver/${dataItem.DriverID}`, .9, .85);
          }}>{dataItem.DriverNumber}</Link></div>
          <div><Link style={{ color: "#007bff" }} to="#" onClick={(e) => {
            e.preventDefault();
            openWindowPerc(`/Assets/Vehicle/${dataItem.VehicleID}`, .9, .85);
          }}>{dataItem.VehicleNumber}</Link></div>
        </> : <div><Link style={{ color: "#007bff" }} to="#" onClick={(e) => {
          e.preventDefault();
          openWindowPerc(`/Assets/Carrier/${dataItem.CarrierID}`, .9, .85);
        }}>{dataItem.CarrierName}</Link></div>}
    </td>
  );
};

const OrderNumberCell = (props: GridCellProps) => {
  const context = useContext(PayListingContext);

  if (!props.field)
    return null;

  let dataItem: PayListingRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      <div><Link style={{ color: "#007bff" }} to="#" onClick={(e) => {
        e.preventDefault();
        openWindowPerc(`/Trip/${dataItem.TripID}/Pay/${dataItem.PaySequence}/${dataItem.PayLetter}`, .9, .85);
      }}>{dataItem.InvoiceNumber}</Link></div>
      <div>{dataItem.OrderID > 0 ? <a
        href="#"
        style={{ color: '#007bff' }}
        onClick={(e) => {
          openWindowPerc(`/Order/${dataItem.OrderID}/Rate/${dataItem.InvoiceSequence}/${dataItem.InvoiceLetter}`, .9, .85);
          e.preventDefault();
        }}>{`${dataItem.OrderNumber}-${dataItem.InvoiceSequence}-${dataItem.InvoiceLetter}`}
      </a> : "-"}
      </div>
    </td>
  );
};

const PickupLocationCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: PayListingRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      <div>{dataItem.PickupCityName}, {dataItem.PickupCityState}</div>
      <div>{Moment.utc(dataItem.PickupDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div>
    </td>
  );
};

const DeliveryLocationCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: PayListingRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      <div>{dataItem.DeliveryCityName}, {dataItem.DeliveryCityState}</div>
      <div>{Moment.utc(dataItem.DeliveryDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div>
    </td>
  );
};

const SplitTitleHeaderCell = (props: GridHeaderCellProps) => {
  const splitVal = props.title.split("/");

  return (<a className="k-link" onClick={props.onClick}>
    {splitVal.map((title, index) => (
      <div key={index}>{title}</div>
    ))}
    {props.children}
  </a>);
};

const AmountHeaderCell = (props: GridHeaderCellProps) => {
  return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
    <div>Pay Amount</div>
    {props.children}
  </a>);
};

const StatusCell = (props: GridCellProps) => {
  const context = useContext(PayListingContext);

  if (!props.field)
    return null;

  let dataItem: PayListingRecord = props.dataItem;

  const displayText = dataItem.VehicleID > 0 ? '-' : dataItem.EPayStatusName;

  let link: JSX.Element | string = displayText;
  if (dataItem.CarrierID != 2046 && dataItem.EPayCode > 0 && dataItem.PayTotal !== 0 && (dataItem.EPayStatus == EpayStatusTypes.NotTransmitted || dataItem.EPayStatus == EpayStatusTypes.Error)) {
    link = <a href='#' className="text-primary" onClick={(e) => { e.preventDefault(); context.TransmitEpayRecord(dataItem); return false; }}>{displayText}</a>
  }

  return (
    <td colSpan={props.colSpan} style={props.style}>
      <div>{dataItem.PayStatusName}</div>
      <div>{link}</div>
    </td>
  );
};

const AmountCell = (props: GridCellProps) => {
  let dataItem: PayListingRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
      <div>
        <span>{formatNumber(dataItem.PayTotal, "c2")}</span>
      </div>
    </td>
  );
};

const CustomDateInput = (props: DateInputProps) => {
  return (
    <DateInput {...props} label={undefined} width={92} formatPlaceholder={{ year: 'yyyy', month: 'MM', day: 'dd' }} />
  );
};

const PayStatuses = [
  { ID: 0, Name: 'Not Rated' },
  { ID: 101, Name: 'Not Rated/Rated' },
  { ID: PayStatus.Rated, Name: 'Rated' },
  { ID: PayStatus.ReadyToPost, Name: 'Ready To Post' },
  { ID: PayStatus.Posted, Name: 'Posted' },
] as IDName[];

const PayListing = () => {
  const render = useRef(true);
  const defaultPostDataState = {
    skip: 0,
    take: 50,
    sort: "TripNumber",
    filters: {
      PickupFromDateTime: Moment().add(-2, 'weeks').startOf('day').utc().toDate(),
      PickupToDateTime: Moment().endOf('day').utc().toDate(),
      PayStatus: 101,
      AssetType: "All Loads",
      TripNumber: null,
      OrderNumber: null
    }
  } as PostDataState


  const SESSION_STORAGE_NAME = "PayListingFilterV1";
  const sessionStorageAndFilter = sessionStorage.getItem(SESSION_STORAGE_NAME);
  let pds = { ...defaultPostDataState };
  if (sessionStorageAndFilter) {
    pds = JSON.parse(sessionStorageAndFilter);
    pds.filters.PickupFromDateTime = Moment(pds.filters.PickupFromDateTime).utc().toDate();
    pds.filters.PickupToDateTime = Moment(pds.filters.PickupToDateTime).utc().toDate();
  }

  const [postDataState, setPostDataState] = useState<PostDataState>(pds);
  useMessage('refresh', (send, payload) => {
    fetchRecords(false);
  });

  const totalRecords = useRef(0);
  const DataState_Take = 50;
  const { alert } = useAlert();
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [loading, setLoading] = useState(true);
  const [payRecords, setPayRecords] = useState<PayListingRecord[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchInput = useCallback(
    debounce((func: () => void) => {
      func();
    }, 750), []);

  const fetchRecords = useCallback((append: boolean, _postDataState: PostDataState = null) => {
    setLoading(true);

    const dataGridState = _postDataState == null ? { ...postDataState } : { ..._postDataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;

      document.querySelector('.PayListing-andFilter .k-grid-content').scrollTop = 0;
      setPostDataState(dataGridState);
    }

    sessionStorage.setItem(SESSION_STORAGE_NAME, JSON.stringify(dataGridState));

    fetchApi(`/api/Settlements/PayListing`, dataGridState, 'POST')
      .then(({ Data, Total }) => {
        totalRecords.current = Total;
        if (!append) {
          setPayRecords(Data);
        } else {
          setPayRecords(prevData => prevData.concat(Data));
        }
        setLoading(false);
      }).catch(async e => {
        setLoading(false);
        if (!e?.status)
          await alert('Error: Please see admin');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
      });
  }, [alert, postDataState]);

  useEffect(() => {
    if (render.current === true) {
      render.current = false;
    } else {
      return;
    }
    fetchRecords(false);
  }, [fetchRecords]);

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (payRecords.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      postDataState.skip = postDataState.skip + postDataState.take;

      fetchRecords(true);
    }
  }

  //const getCurrentSort = () => {
  //  if (postDataState.sort == "TripNumber") {
  //    return {
  //      allowUnsort: true,
  //      initialSort: [
  //        {
  //          field: postDataState.sort,
  //          dir: 'asc',
  //        },
  //      ],
  //    };
  //  }
  //};

  //const TripPayPopup = (payWindowStatus: PayWindowState) => {
  //  return <TripProfile tripId={payWindowStatus.TripID} isDialog={true} onClose={() => setShowPayWindow(null)}>
  //    <TripPay tripId={payWindowStatus.TripID} sequence={payWindowStatus.Sequence} letter={payWindowStatus.Letter} />
  //  </TripProfile>
  //}

  const SaveAndFetchPostDataState = (o: PostDataState) => {
    setPostDataState(o);
    debounceSearchInput(() => fetchRecords(false, o));
  }

  const updateObject = (updatedObject: PayListingRecord) => {
    const index = payRecords.findIndex((obj) => obj.PayID === updatedObject.PayID);

    if (index > -1) {
      const updatedObjects = [...payRecords];
      updatedObjects[index] = updatedObject;
      setPayRecords(updatedObjects);
    }
  };

  const TransmitEpayRecord = async (payRecord: PayListingRecord) => {
    //if (await confirm('Transmit to Epay?') === false)
    //  return;
    setLoading(true);
    fetchApi(`/api/Settlements/TransmitEpayRecord/${payRecord.PayID}`, {}, 'POST')
      .then(() => {

        payRecord.EPayStatus = EpayStatusTypes.ReadyToTransmit;
        payRecord.EPayStatusName = EpayStatuses.find(x => x.ID == payRecord.EPayStatus).Name;

        updateObject(payRecord);
        setLoading(false);
      }).catch(async e => {
        setLoading(false);
        if (!e?.status)
          await alert('Error: Please see admin');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
      });
  }

  const dataView = () => {
    return (<div className="mt-3" style={{ position: "relative" }}>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <Title string="Pay Listing" />
      <h4 className="text-left">Pay Listing</h4>
      <PayListingContext.Provider value={{
        TransmitEpayRecord: TransmitEpayRecord
      }}>
        <Grid
          className='PayListing-andFilter'
          style={{
            maxHeight: `${window.innerHeight * .80}px`,
          }}
          data={payRecords}
          total={totalRecords.current}
          onScroll={scrollHandler}
          resizable={true}
        //sortable={getCurrentSort()}
        >
          <GridToolbar>
            {/* <label className="mt-1">Filters:</label>*/}
            <NumericTextBox
              value={postDataState.filters?.TripNumber}
              placeholder='Trip Number'
              min={0}
              spinners={false}
              width={200}
              format="0"
              onChange={(e) => {
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, TripNumber: e?.value } });
              }}
            />

            <NumericTextBox
              value={postDataState.filters?.OrderNumber}
              placeholder='Order Number'
              min={0}
              spinners={false}
              width={200}
              format="0"
              onChange={(e) => {
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, OrderNumber: e?.value } });
              }}
            />

            <DriverAutoComplete
              selectedDriverID={postDataState.filters?.DriverID}
              style={{ width: '200px' }}
              onSelectedDriver={(e) => {
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, DriverID: e?.DriverID } });
              }}
            />

            <VehicleAutoComplete
              selectedVehicleID={postDataState.filters?.VehicleID}
              style={{ width: '200px' }}
              onSelectedVehicle={(e) => {
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, VehicleID: e?.VehicleID } });
              }}
            />

            <OwnerAutoComplete
              selectedOwnerID={postDataState.filters?.OwnerID}
              style={{ width: '200px' }}
              onSelectedOwner={(e) => {
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, OwnerID: e?.OwnerID } });
              }}
            />

            <CarrierAutoComplete
              selectedCarrierID={postDataState.filters?.CarrierID}
              style={{ width: '200px' }}
              onSelectedCarrier={(e) => {
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, CarrierID: e?.CarrierID } });
              }}
            />

            <DropDownList
              style={{ width: '145px' }}
              data={PayStatuses}
              textField="Name"
              dataItemKey="ID"
              value={PayStatuses.find((x: IDName) => x.ID == postDataState.filters.PayStatus)}
              onChange={(e) => {
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, PayStatus: e.target.value.ID } });
              }}
            />

            <DropDownList
              style={{ width: "125px" }}
              data={['All Loads', 'Asset Loads', 'Carrier Loads']}
              value={['All Loads', 'Asset Loads', 'Carrier Loads'].find(x => x === postDataState.filters.AssetType)}
              defaultValue={'All Loads'}
              onChange={(e) => {
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, AssetType: e.value } });
              }}
            />

            <PayPeriodDropDownList
              style={{ width: '225px' }}
              onSelected={(e) => {
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, PayPeriodID: e.PayPeriodID } });
              }}
              selectedPayPeriodID={postDataState.filters?.PayPeriodID ?? 0}
              includeClosedPayPeriods={true}
              includeOpenPayPeriods={true}
              defaultPayPeriodItem={{ PayPeriodID: 0, Label: 'Any Pay Period' }}
            />

            <DropDownList
              style={{ width: '150px' }}
              data={EpayStatuses}
              textField="Name"
              dataItemKey="ID"
              value={EpayStatuses.find((x: IDName) => x.ID == (postDataState.filters.EpayStatus ?? -1))}
              onChange={(e) => {
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, EpayStatus: e.target.value.ID } });
              }}
            />

            <Button
              title="Clear All Filters and Sort"
              icon="filter-clear"
              svgIcon={filterClearIcon}
              onClick={() => {
                SaveAndFetchPostDataState({ ...defaultPostDataState });
              }}
            />
          </GridToolbar>
          <GridToolbar>
            <span>
              <label className="mt-1">Pick-Up Date Range:</label>
              <SimpleDateRangePicker
                value={{ start: postDataState.filters.PickupFromDateTime, end: postDataState.filters.PickupToDateTime }}
                onChange={(e) => {
                  setPostDataState({ ...postDataState, filters: { ...postDataState.filters, PickupFromDateTime: e.start, PickupToDateTime: e.end } });
                  if (e && e.start && e.end) {
                    debounceSearchInput(() => {
                      SaveAndFetchPostDataState({
                        ...postDataState, filters: {
                          ...postDataState.filters,
                          PickupFromDateTime: Moment(e.start).startOf('day').utc().toDate(),
                          PickupToDateTime: Moment(e.end).endOf('day').utc().toDate()
                        }
                      });
                    });
                  }
                }}
              />
            </span>
          </GridToolbar>
          <Column field="DriverID" title="Driver/Vehicle" cell={AssetCell} headerCell={SplitTitleHeaderCell} />
          <Column field="InvoiceNumber" title="Trip #/Order #" cell={OrderNumberCell} headerCell={SplitTitleHeaderCell} />
          <Column field="PickupCityName" title="From" cell={PickupLocationCell} />
          <Column field="DeliveryCityName" title="To" cell={DeliveryLocationCell} />
          <Column field="Amount" title="Amount" cell={AmountCell} headerCell={AmountHeaderCell} filterable={false} />
          <Column field="Status" title="Status/EPay Status" cell={StatusCell} width={125} filterable={false} headerCell={SplitTitleHeaderCell} />
        </Grid>
      </PayListingContext.Provider>
      {
        totalRecords.current > 0 &&
        <div className="k-pager k-pager-md k-grid-pager">
          <div style={{ marginLeft: "auto", marginRight: 0 }}>
            {postDataState.skip + postDataState.take > totalRecords.current ? totalRecords.current : postDataState.skip + postDataState.take} of {totalRecords.current} items
          </div>
        </div>
      }
      <ConfirmationDialog />
    </div>);
  };

  return dataView();
}

export default PayListing;
